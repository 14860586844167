import { Injectable } from "@angular/core";
import { NavigationStart, Router, UrlTree } from "@angular/router";
import { IonRouterOutlet, NavController, Platform } from "@ionic/angular";
import { NavigationOptions } from "@ionic/angular/common/providers/nav-controller";
import { NavParamsProvider } from "../providers/navParams.provider";
import { LoggerService } from "../services/logs/logger.service";
import { DeviceHelper } from "./device.helper";
import { LoadingHelper } from "./loading.helper";

@Injectable({
    providedIn: "root",
})
export class NavControllerExtended {
    private backPressed: boolean = false;
    private popping: boolean = false;
    private routerOutlet: IonRouterOutlet;

    constructor(private logger: LoggerService,
                private platform: Platform,
                private deviceHelper: DeviceHelper,
                private loadingHelper: LoadingHelper,
                private navController: NavController,
                private router: Router,
                private navParamsProvider: NavParamsProvider) {
    }

    get params(): NavParamsProvider {
        return this.navParamsProvider;
    }

    public initialize(routerOutlet: IonRouterOutlet) {
        this.routerOutlet = routerOutlet;

        this.router.events.subscribe(e => {
            if (e instanceof NavigationStart) {
                this.popping = (e.navigationTrigger == "popstate") || this.backPressed;
                this.backPressed = false;
            }
        });

        if (this.deviceHelper.isRunningOnDevice()) {
            this.platform.backButton.subscribeWithPriority(0, () => {
                this.onBackButtonPressed();
            });
        }
    }

    public getUrl() {
        return this.router.url;
    }

    public onBackButtonPressed() {
        if (this.loadingHelper.isVisible()) {
            return;
        }

        // Can we go back?
        if (this.routerOutlet && this.routerOutlet.canGoBack()) {
            this.backPressed = true;
            this.pop();
        }
    }

    isPopping(): boolean {
        return this.popping;
    }

    public navigateForward(url: string | UrlTree | any[], params?: any, options?: NavigationOptions): Promise<boolean> {
        this.logger.info(this.constructor.name, "navigateForward", url);

        this.navParamsProvider.clearParams();
        if (params) {
            this.navParamsProvider.setParams(params);
        }
        return this.navController.navigateForward(url, options);
    }

    public navigateBack(url: string | UrlTree | any[], params?: any, options?: NavigationOptions): Promise<boolean> {
        this.logger.info(this.constructor.name, "navigateBack", url);

        this.navParamsProvider.clearParams();
        if (params) {
            this.navParamsProvider.setParams(params);
        }
        return this.navController.navigateBack(url, options);
    }

    public navigateRoot(url: string | UrlTree | any[], params?: any, options?: NavigationOptions): Promise<boolean> {
        this.logger.info(this.constructor.name, "navigateRoot", url);

        this.navParamsProvider.clearParams();
        if (params) {
            this.navParamsProvider.setParams(params);
        }
        return this.navController.navigateRoot(url, options);
    }

    public back() {
        this.logger.info(this.constructor.name, "back");

        this.navParamsProvider.clearParams();
        this.navController.back();
    }

    public pop() {
        this.logger.info(this.constructor.name, "pop");

        this.navParamsProvider.clearParams();
        return this.navController.pop();
    }
}
