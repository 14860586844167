import { NotImplementedError } from "../../gyzmo-commons/helpers/NotImplementedError";
import { BaseDto } from "./base/base.dto";

export class ExpenseCategoryDto extends BaseDto<void> {
    public id: string = "";
    public isVatExempt: boolean = false;
    public wording: string = "";

    public toBody(): any {
        const clone: any = { ...this };

        return clone;
    }

    public toModel(): void {
        throw new NotImplementedError();
    }
}
