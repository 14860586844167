import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { FileService } from "../../../core/services/file.service";
import { PipesModule } from "../../../gyzmo-commons/helpers/pipes/pipes.module";
import { ReturnSearchResultComponent } from "./return-search-result.component";

@NgModule(
    {
        declarations: [
            ReturnSearchResultComponent,
        ],
        imports: [
            CommonModule,
            IonicModule,
            HttpClientModule,
            TranslateModule,
            PipesModule,
        ],
        exports: [
            ReturnSearchResultComponent,
        ],
        providers: [
            FileService,
        ],
    })
export class ReturnSearchResultComponentModule {
}
