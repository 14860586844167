import { Injectable } from "@angular/core";
import { HttpClientProvider } from "../../providers/httpClientProvider";
import { isNullOrEmpty } from "../../helpers/null.helper";
import { HttpResponse } from "../../http/httpResponse";
import { LoggerService } from "../logs/logger.service";
import { GeocoderForwardResult } from "./geocoderForwardResult";
import { GeocoderReverseResult } from "./geocoderReverseResult";

@Injectable({
    providedIn: "root",
})
export class FrenchGovernmentApiGeocoding {
    constructor(private logger: LoggerService,
                private httpClientProvider: HttpClientProvider) {
    }

    public reverseGeocode(latitude: number, longitude: number): Promise<GeocoderReverseResult[]> {
        return new Promise<GeocoderReverseResult[]>((resolve, reject) => {
            let result: GeocoderReverseResult[] = [];

            this.httpClientProvider.getHttpClient().get("https://api-adresse.data.gouv.fr/reverse/?lat=" + latitude + "&lon=" + longitude, {})
                .then((response: HttpResponse) => {
                    if (!response.body) {
                        reject();
                        return;
                    }

                    (response.body.features as Array<any>).forEach(element => {
                        let geocoderReverseResult: GeocoderReverseResult = new GeocoderReverseResult();

                        geocoderReverseResult.thoroughfare = "";
                        if (!isNullOrEmpty(element.properties.housenumber)) {
                            geocoderReverseResult.thoroughfare = element.properties.housenumber.trim();
                        }
                        geocoderReverseResult.thoroughfare += " ";
                        if (!isNullOrEmpty(element.properties.street)) {
                            geocoderReverseResult.thoroughfare += element.properties.street.trim();
                        }
                        geocoderReverseResult.thoroughfare = geocoderReverseResult.thoroughfare.trim();

                        geocoderReverseResult.subThoroughfare = "";
                        geocoderReverseResult.postalCode = element.properties.postcode.trim();
                        geocoderReverseResult.locality = element.properties.city.trim();

                        result.push(geocoderReverseResult);
                    });

                    resolve(result);
                })
                .catch(reason => {
                    this.logger.error(this.constructor.name, reason);
                    reject();
                });
        });
    }

    public forwardGeocode(address: string): Promise<GeocoderForwardResult[]> {
        return new Promise<GeocoderForwardResult[]>((resolve, reject) => {
            let result: GeocoderForwardResult[] = [];

            this.httpClientProvider.getHttpClient().get("https://api-adresse.data.gouv.fr/search/?q=" + address, {})
                .then((response: HttpResponse) => {
                    if (!response.body) {
                        reject();
                        return;
                    }

                    (response.body.features as Array<any>).forEach(element => {
                        let geocoderForwardResult: GeocoderForwardResult = new GeocoderForwardResult();

                        geocoderForwardResult.description = element.properties.label.trim();
                        geocoderForwardResult.longitude = element.geometry.coordinates[0];
                        geocoderForwardResult.latitude = element.geometry.coordinates[1];

                        result.push(geocoderForwardResult);
                    });

                    resolve(result);
                })
                .catch(reason => {
                    this.logger.error(this.constructor.name, reason);
                    reject();
                });
        });
    }

    public listCitiesFromPostalCode(postalCode: string): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            let result: string[] = [];

            this.httpClientProvider.getHttpClient().get("https://geo.api.gouv.fr/communes?codePostal=" + postalCode + "&fields=nom&format=json", {})
                .then((response: HttpResponse) => {
                    if (!response.body) {
                        reject();
                        return;
                    }

                    response.body.forEach(entry => {
                        result.push(entry.nom);
                    });

                    resolve(result);
                })
                .catch(reason => {
                    this.logger.error(this.constructor.name, reason);
                    reject();
                });
        });
    }
}
