import { Component } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { EquipmentDto } from "../../../core/dto/equipment.dto";
import { ModelDto } from "../../../core/dto/model.dto";
import { EquipmentService } from "../../../core/services/equipment.service";
import { LoadingHelper } from "../../../gyzmo-commons/helpers/loading.helper";
import { NavControllerExtended } from "../../../gyzmo-commons/helpers/navControllerExtended";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";

@Component({
    templateUrl: "equipment-search.modal.html",
    styleUrls: ["equipment-search.modal.scss"],
})
export class EquipmentSearchModal {
    public model: ModelDto;
    public equipments: EquipmentDto[];
    public filteredEquipments: EquipmentDto[];
    public filterTerm: string = "";
    public showHelp: boolean = false;
    public searching: boolean = false;

    constructor(navControllerExtended: NavControllerExtended,
                private modalController: ModalController,
                private loadingHelper: LoadingHelper,
                private equipmentService: EquipmentService) {
        this.model = navControllerExtended.params.getParam("model");
    }

    public async ionViewWillEnter() {
        this.searching = true;
        this.equipments = await this.equipmentService.getListByModel(this.model);
        this.filteredEquipments = this.equipments;
        this.searching = false;
    }

    public dismiss(data?: any) {
        this.modalController.dismiss(data);
    }

    public filter() {
        if (isNullOrEmpty(this.filterTerm)) {
            this.filteredEquipments = this.equipments;
            return;
        }

        this.filteredEquipments = this.equipments.filter(element => {
            if (element.title
                && element.title.toLowerCase().indexOf(this.filterTerm.toLowerCase()) >= 0) {
                return true;
            }

            if (element.registrationNumber
                && element.registrationNumber.toLowerCase().indexOf(this.filterTerm.toLowerCase()) >= 0) {
                return true;
            }

            if (element.id
                && element.id.toLowerCase().indexOf(this.filterTerm.toLowerCase()) >= 0) {
                return true;
            }

            return false;
        });
    }

    async selectEquipment(equipmentDto: EquipmentDto) {
        await this.loadingHelper.showLoading();

        this.equipmentService.getById(equipmentDto)
            .then(async updatedEquipmentDto => {
                this.dismiss(updatedEquipmentDto);
                await this.loadingHelper.hideAll();
            });
    }
}
