import { Component, Input } from "@angular/core";
import { ReturnSearchResultDto } from "../../../core/dto/returnSearchResult.dto";
import { AddressHelper } from "../../../core/helpers/address.helper";

@Component({
    selector: "return-search-result",
    templateUrl: "return-search-result.component.html",
    styleUrls: ["return-search-result.component.scss"],
})
export class ReturnSearchResultComponent {
    addressHelper = AddressHelper;
    @Input() returnSearchResult: ReturnSearchResultDto;

}
