// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-title.md {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/gyzmo-commons/components/date-picker/date-picker-popover/date-picker-popover.component.scss"],"names":[],"mappings":"AACI;EACI,UAAA;AAAR","sourcesContent":["ion-title {\r\n    &.md {\r\n        padding: 0;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
