import { Injectable } from "@angular/core";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/NotImplementedError";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { HourTypeDto } from "../../dto/hourType.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class HourTypeWsDao extends WsDao<HourTypeDto> {

    constructor(private logger: LoggerService) {
        super();
    }

    public getById(serverConnection: ServerConnection, id: string): Promise<HourTypeDto> {
        throw new NotImplementedError();
    }

    public save(serverConnection: ServerConnection, inspection: HourTypeDto): Promise<HourTypeDto> {
        throw new NotImplementedError();
    }

    public getList(serverConnection: ServerConnection): Promise<HourTypeDto[]> {
        return new Promise<HourTypeDto[]>(async (resolve, reject) => {
            let allHourKinds = [];

            let filters = ["HRS", "REPAS"];
            for (const filter of filters) {
                let tokens = new Map<string, string>();
                tokens.set("hourKind", filter);

                let hourKinds = await serverConnection.get(this.constructor.name, "services?_limit=NOLIMIT&hasHourType=true&hourType=:hourKind", tokens)
                    .then(async response => {
                        let hourKinds: HourTypeDto[] = [];
                        let filteredHourKinds: HourTypeDto[] = [];

                        (response.body as Array<any>).forEach(value => {
                            let hourKind = HourTypeDto.fromBody(value, filter);
                            hourKinds.push(hourKind);
                        });

                        for (const hourKind of hourKinds) {
                            let response = await serverConnection.get(this.constructor.name, "services/" + hourKind.id, tokens);
                            if (response.body.hourType && response.body.hourType.id) {
                                hourKind.hourType = response.body.hourType.id;
                                filteredHourKinds.push(hourKind);
                            }
                        }

                        return filteredHourKinds;
                    })
                    .catch(reason => {
                        this.logger.error(this.constructor.name, reason);

                        return [];
                    });

                allHourKinds = allHourKinds.concat(hourKinds);
            }

            resolve(allHourKinds);
        });
    }
}
