export class FileHelper {
    public static addDataUrlHeaderToBase64(base64: string, mimeType: string): string {
        return "data:" + mimeType + ";base64," + base64;
    }

    public static extractMimeType(fileContent: string) {
        try {
            return fileContent.substring(fileContent.indexOf(":") + 1, fileContent.indexOf(";"));
        } catch {
            return "";
        }
    }

    public static guessDataUrlHeader(firstCharOfBase64: string) {
        switch (firstCharOfBase64) {
            case "i": {
                return "data:image/png;base64,";
            }
            case "R": {
                return "data:image/gif;base64,";
            }
            case "J": {
                return "data:application/pdf;base64,";
            }
            case "P": {
                return "data:text/plain;base64,";
            }
            default: {
                // / = JPEG
                return "data:image/jpeg;base64,";
            }
        }
    }

    public static getExtension(fileContent: string) {
        if (this.isPDF(fileContent)) {
            return ".pdf";
        }
        if (this.isTxt(fileContent)) {
            return ".txt";
        }
        if (this.isJPEG(fileContent)) {
            return ".jpg";
        }
        if (this.isPNG(fileContent)) {
            return ".png";
        }
        if (fileContent.startsWith("data:application/msword")) {
            return ".doc";
        }
        if (fileContent.startsWith("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
            return ".docx";
        }
        if (fileContent.startsWith("data:text/csv")
            || fileContent.startsWith("data:text/comma-separated-values")) {
            return ".csv";
        }
        if (fileContent.startsWith("data:application/vnd.ms-excel")) {
            return ".xls";
        }
        if (fileContent.startsWith("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            return ".xlsx";
        }

        return "";
    }

    public static isPDF(fileContent: string) {
        return fileContent.startsWith("data:application/pdf");
    }

    public static isJPEG(fileContent: string) {
        return fileContent.startsWith("data:image/jpeg");
    }

    public static isPNG(fileContent: string) {
        return fileContent.startsWith("data:image/png");
    }

    public static isWord(fileContent: string) {
        return fileContent.startsWith("data:application/msword")
               || fileContent.startsWith("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document");
    }

    public static isExcel(fileContent: string) {
        return fileContent.startsWith("data:text/csv")
               || fileContent.startsWith("data:text/comma-separated-values")
               || fileContent.startsWith("data:application/vnd.ms-excel")
               || fileContent.startsWith("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
    }

    public static isTxt(fileContent: string) {
        return fileContent.startsWith("data:text/plain");
    }
}
