import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { GtagService } from "../../gyzmo-commons/gtag/gtag.service";
import { isNullOrUndefined } from "../../gyzmo-commons/helpers/null.helper";
import { VersionHelper } from "../../gyzmo-commons/helpers/version.helper";
import { AppSqlProvider } from "../../gyzmo-commons/persistence/app.sql.provider";
import { Database } from "../../gyzmo-commons/persistence/database";
import { AppVersionService } from "../../gyzmo-commons/services/appVersion.service";
import { CacheService } from "../../gyzmo-commons/services/cache.service";
import { UserDbDao } from "../dao/db/user.db.dao";
import { UserWsDao } from "../dao/ws/user.ws.dao";
import { UserDto } from "../dto/user.dto";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class UserService {
    constructor(private database: Database,
                private appSqlProvider: AppSqlProvider,
                private imgCacheService: CacheService,
                private serversConnectionsProvider: ServersConnectionsProvider,
                private userWsDao: UserWsDao,
                private userDbDao: UserDbDao,
                private gtagService: GtagService,
                private appVersionService: AppVersionService) {
    }

    public isConnected(): Promise<boolean> {
        return this.getCurrentUser(false)
            .then((user: UserDto) => {
                return !isNullOrUndefined(user);
            });
    }

    public getCurrentUser(hydrateUser: boolean): Promise<UserDto> {
        return this.userDbDao.get(null, hydrateUser)
            .then(user => {
                if (user) {
                    return UserDto.fromModel(user);
                } else {
                    return null;
                }
            });
    }

    public getCurrentUserWithThirdPartyOnly(): Promise<UserDto> {
        return this.userDbDao.getCurrentUserWithThirdPartyOnly()
            .then(user => {
                if (user) {
                    return UserDto.fromModel(user);
                } else {
                    return null;
                }
            });
    }

    public async connect(login: string, password: string): Promise<UserDto> {
        await this.disconnect(false);

        let result: { token: string, minVersion: number[] } = await this.userWsDao.connect(this.serversConnectionsProvider.getServerConnection(), login, password);
        await this.serversConnectionsProvider.getServerConnection().setAuthentication(result.token);

        let appVersion = await this.appVersionService.getAppVersion();
        let appVersionNumbers = VersionHelper.parseVersion(appVersion.version);

        if (appVersion.version != "dev" && VersionHelper.compareVersions(result.minVersion, appVersionNumbers) > 0) {
            throw new Error("Votre version n'est pas à jour, veuillez télécharger la dernière version en vous rendant sur le store.");
        }

        let userDto = await this.userWsDao.getMe(this.serversConnectionsProvider.getServerConnection());
        await this.userDbDao.save(userDto.toModel());

        // noinspection ES6MissingAwait
        this.gtagService.login();

        return userDto;
    }

    public async disconnect(remoteLogout: boolean): Promise<boolean> {
        let connectedUser = await this.userDbDao.getCurrentUserWithThirdPartyOnly();
        await this.userWsDao.disconnect(this.serversConnectionsProvider.getServerConnection(), remoteLogout);

        await this.database.cleanOnDisconnection();
        await this.imgCacheService.cleanCache();

        if (environment.mocked) {
            //this.orderService.clearMockCache();
        }

        // In case the bulk mode was active
        await this.appSqlProvider.commitBulk();

        return connectedUser != null;
    }

    public async save(user: UserDto): Promise<UserDto> {
        let updatedDto = await this.userWsDao.save(this.serversConnectionsProvider.getServerConnection(), user);

        await this.userDbDao.save(updatedDto.toModel());
        return updatedDto;
    }

    public async checkToken(): Promise<boolean> {
        let result = true;
        let value = await this.userWsDao.checkToken(this.serversConnectionsProvider.getServerConnection());
        if (!value) {
            await this.disconnect(true);
            result = false;
        }

        return result;
    }
}
