import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { MovementWsDao } from "src/core/dao/ws/movement.ws.dao";
import { ConstructionSiteWsDao } from "../../../core/dao/ws/constructionSite.ws.dao";
import { EquipmentWsDao } from "../../../core/dao/ws/equipment.ws.dao";
import { InvoiceWsDao } from "../../../core/dao/ws/invoice.ws.dao";
import { InvoiceLineWsDao } from "../../../core/dao/ws/invoiceLine.ws.dao";
import { LocationWsDao } from "../../../core/dao/ws/location.ws.dao";
import { MaterialsWsDao } from "../../../core/dao/ws/materials.ws.dao";
import { OrderWsDao } from "../../../core/dao/ws/order.ws.dao";
import { ThirdPartyWsDao } from "../../../core/dao/ws/thirdParty.ws.dao";
import { PipesModule } from "../../../gyzmo-commons/helpers/pipes/pipes.module";
import { ReturnSearchResultComponentModule } from "../return-search-result/return-search-result.module";
import { ContractSearchComponent } from "./contract-search.component";

@NgModule(
    {
        declarations: [
            ContractSearchComponent,
        ],
        imports: [
            CommonModule,
            FormsModule,
            IonicModule,
            PipesModule,
            TranslateModule,
            ReturnSearchResultComponentModule,
        ],
        providers: [
            InvoiceWsDao,
            InvoiceLineWsDao,
            MovementWsDao,
            EquipmentWsDao,
            MaterialsWsDao,
            ConstructionSiteWsDao,
            ThirdPartyWsDao,
            MovementWsDao,
            LocationWsDao,
            OrderWsDao,
        ],
        exports: [
            ContractSearchComponent,
        ],
    })

export class ContractSearchModalModule {
}
