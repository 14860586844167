import { Injectable } from "@angular/core";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/NotImplementedError";
import { DeliveryDto } from "../../dto/delivery.dto";
import { ExpenseDto } from "../../dto/expense.dto";
import { ExpenseCategoryDto } from "../../dto/expenseCategory.dto";
import { ExpenseStateDto } from "../../dto/expenseState.dto";
import { ThirdPartyDto } from "../../dto/thirdParty.dto";
import { TimeDto } from "../../dto/time.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";
import { DeliveryStates } from "../../interfaces/deliveryStates";
import { LinkedMovementDtoGroup } from "../../interfaces/linked-movement-dto.group";
import { HourTypeDbDao } from "../db/hourType.db.dao";
import { ExpenseWsDao } from "./expense.ws.dao";

@Injectable({
    providedIn: "root",
})
export class TimeWsDao extends WsDao<TimeDto> {
    constructor(private hourTypeDbDao: HourTypeDbDao,
                private expenseWsDao: ExpenseWsDao) {
        super();
    }

    public getById(serverConnection: ServerConnection, id: string): Promise<TimeDto> {
        throw new NotImplementedError();
    }

    public save(serverConnection: ServerConnection, entity: TimeDto): Promise<TimeDto> {
        throw new NotImplementedError();
    }

    public async recordTimeForGroup(serverConnection: ServerConnection, group: LinkedMovementDtoGroup, timeDto: TimeDto): Promise<TimeDto> {
        let expense = new ExpenseDto();
        delete expense.id;
        delete expense.supplier;

        expense.linkedMovement = { id: timeDto.fatherMovementId };

        expense.customer = new ThirdPartyDto();
        expense.customer.id = timeDto.customerId;

        expense.driver = new ThirdPartyDto();
        expense.driver.id = group.parentDelivery.driver.id;

        if (timeDto.type == DeliveryStates.WAITING) {
            expense.category = new ExpenseCategoryDto();
            expense.category.id = await this.getHourType("HRS_ATTENTE");
        } else if (timeDto.type == DeliveryStates.MISC) {
            expense.category = new ExpenseCategoryDto();
            expense.category.id = await this.getHourType("HRS_DIVERS");
        }

        expense.date = group.parentDelivery.startDate;
        expense.kilometers = timeDto.getDurationAsHour();
        expense.type = { id: "H" };
        expense.startDate = timeDto.startTime;
        expense.endDate = timeDto.endTime;

        expense.state = new ExpenseStateDto();
        expense.state.id = "HEURE_PREPA";

        expense.constructionSite = { id: group.constructionSite.id };
        expense.amount = 0;

        expense["paymentMethod"] = { id: "SANS" };

        await this.expenseWsDao.save(serverConnection, expense);

        return timeDto;
    }

    public async recordTimeForDelivery(serverConnection: ServerConnection, deliveryDto: DeliveryDto, timeDto: TimeDto) {
        let expense = new ExpenseDto();
        delete expense.id;
        delete expense.supplier;
        delete expense.constructionSite;

        expense.linkedMovement = { id: timeDto.fatherMovementId };

        expense.customer = new ThirdPartyDto();
        expense.customer.id = timeDto.customerId;

        expense.driver = new ThirdPartyDto();
        expense.driver.id = deliveryDto.driver.id;

        if (timeDto.type == DeliveryStates.PAUSED) {
            expense.category = new ExpenseCategoryDto();
            expense.category.id = await this.getHourType("HRS_PAUSE");
        } else if (timeDto.type == DeliveryStates.ENDED) {
            expense.category = new ExpenseCategoryDto();
            expense.category.id = await this.getHourType("HRS_INTERV");
        }

        expense.date = deliveryDto.startDate;
        expense.kilometers = timeDto.getDurationAsHour();
        expense.type = { id: "H" };
        expense.startDate = timeDto.startTime;
        expense.endDate = timeDto.endTime;

        expense.state = new ExpenseStateDto();
        expense.state.id = "HEURE_PREPA";

        expense.amount = 0;

        expense["paymentMethod"] = { id: "SANS" };

        await this.expenseWsDao.save(serverConnection, expense);

        return timeDto;
    }

    private async getHourType(kindFilter: string) {
        try {
            return (await this.hourTypeDbDao.list(kindFilter))[0].id;
        } catch (e) {
            return kindFilter;
        }
    }
}
