/* eslint-disable no-console */
import { Mutex } from "async-mutex";
import { Components, SQLiteValues } from "jeep-sqlite";
import { Subscription } from "rxjs";
import { DeviceHelper } from "../helpers/device.helper";
import { SqlHelper } from "../helpers/sql.helper";
import { DatabaseLocation } from "../interfaces/databaseLocation";
import { SQLiteResult, SqlProvider } from "../interfaces/sqlProvider";
import { LoggerService } from "../services/logs/logger.service";
import { DatabaseEventService } from "./databaseEvent.service";

export class JeepSqlBrowserSqlProvider implements SqlProvider {
    public dbName: string;
    private initialized: boolean = false;
    private jeepSqlite: Components.JeepSqlite;
    private mutex = new Mutex();
    private subscription: Subscription;

    public constructor(protected logger: LoggerService,
                       protected databaseEventService: DatabaseEventService,
                       protected deviceHelper: DeviceHelper) {
    }

    public getDbName(): string {
        return this.dbName;
    }

    public isInitialized() {
        return this.initialized;
    }

    public async initialize(dbName: string,
                            location: DatabaseLocation): Promise<boolean> {
        if (!this.deviceHelper.isRunningOnDevice()) {
            this.logger.debug(this.constructor.name, "Opening database " + dbName);

            this.addDomElements();

            await customElements.whenDefined("jeep-sqlite");
            this.jeepSqlite = document.querySelector("jeep-sqlite");
            if (await this.jeepSqlite.isStoreOpen()) {
                try {
                    await this.jeepSqlite.createConnection({
                        database: dbName,
                        version: 1,
                    });

                    await this.jeepSqlite.open({ database: dbName });
                    const isDB = await this.jeepSqlite.isDBOpen({ database: dbName });
                    if (isDB && isDB.result) {
                        this.logger.debug(this.constructor.name, "Database " + dbName + " successfully opened.");
                        this.logger.warn(this.constructor.name, "You can display database content by typing traceDb() in console.");
                        this.dbName = dbName;
                        this.initialized = true;

                        this.subscription = this.databaseEventService.getDatabaseLogSubject().subscribe(() => {
                            void this.databaseLog();
                        });

                        return true;
                    }
                } catch (err) {
                    this.logger.error(this.constructor.name, `Error ${ err }`);
                    return false;
                }
            }
        } else {
            throw new Error("Jeep Sql is not supposed to be used on a device !");
        }
    }

    public async getExistingTables(): Promise<string[]> {
        let result = await this.jeepSqlite.getTableList({ database: this.dbName });
        return result.values;
    }

    public async close(): Promise<boolean> {
        this.logger.debug(this.constructor.name, "Closing database " + this.dbName);

        if (!this.isInitialized()) {
            this.logger.debug(this.constructor.name, "No database to close");
            return true;
        } else {
            await this.jeepSqlite.close({ database: this.dbName });
            this.initialized = false;
            this.dbName = "";

            if (this.subscription) {
                this.subscription.unsubscribe();
            }

            return true;
        }
    }

    public async query(queryText: string, bulkable: boolean = true): Promise<SQLiteResult> {
        let t0 = performance.now();

        let result: SQLiteResult = null;
        const mutexReleaser = await this.mutex.acquire();
        if (queryText.toUpperCase().startsWith("SELECT")) {
            result = await this.jeepSqlite.query({ database: this.dbName, statement: queryText })
                .then((result) => {
                    return this.toSqliteResult(result);
                })
                .catch((err) => {
                    return new SQLiteResult(false, null, err);
                });
        } else {
            result = await this.jeepSqlite.run({ database: this.dbName, statement: queryText })
                .then((result) => {
                    return new SQLiteResult(true, [], null, result.changes.lastId);
                })
                .catch((err) => {
                    return new SQLiteResult(false, null, err);
                });
        }
        mutexReleaser();

        let t1 = performance.now();
        this.logger.debug(this.constructor.name,
            "Database=" + this.dbName,
            "Query=\"" + queryText + "\"",
            "Result=" + SqlHelper.stringifySqlResult(result),
            "[" + Math.floor(t1 - t0) + "ms]");

        return result;
    }

    public async dropDatabase(): Promise<void> {
        this.logger.debug(this.constructor.name, "dropDatabase");

        await this.jeepSqlite.deleteDatabase({ database: this.dbName });
    }

    public enableBulkWriting() {
        this.logger.warn(this.constructor.name, "No bulk mode on jeep-sql.");
    }

    public disableBulkWriting() {
        this.logger.warn(this.constructor.name, "No bulk mode on jeep-sql.");
    }

    public commitBulk(): Promise<void> {
        this.logger.warn(this.constructor.name, "No bulk mode on jeep-sql.");
        return Promise.resolve();
    }

    private async databaseLog() {
        let databases = await this.jeepSqlite.getDatabaseList();
        for (const database of databases.values) {
            let dnName = database.replace("SQLite.db", "");
            console.info("----- Tracing database " + dnName + " -----");
            let tables = await this.jeepSqlite.getTableList({ database: dnName });
            for (const table of tables.values) {
                console.info("- Table " + table + " -");
                let tableValues = await this.jeepSqlite.query({ database: dnName, statement: "SELECT * FROM " + table });
                console.table(tableValues.values);
            }
        }
    }

    private toSqliteResult(sqLiteValues: SQLiteValues) {
        let result = new SQLiteResult(true, sqLiteValues.values);
        return result;
    }

    private addDomElements() {
        let dumpScript = document.createElement("script");
        dumpScript.textContent = "function traceDB() {"
                                 + "  const event = new CustomEvent('traceDB', { });"
                                 + "  document.dispatchEvent(event);"
                                 + "  return 'Starting to trace all databases content :';"
                                 + "}";
        document.body.appendChild(dumpScript);

        this.databaseEventService.addDom();

        let jeepScript = document.createElement("script");
        jeepScript.setAttribute("type", "module");
        jeepScript.setAttribute("src", "assets/jeep-sqlite.esm.js");
        document.body.appendChild(jeepScript);

        let jeep = document.createElement("jeep-sqlite");
        jeep.setAttribute("autoSave", "true");
        jeep.setAttribute("wasmPath", "/assets");
        document.body.appendChild(jeep);
    }
}
