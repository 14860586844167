import { Injectable } from "@angular/core";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/NotImplementedError";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { XVEGAID } from "../../../gyzmo-commons/http/header.constant";
import { ExpenseDto } from "../../dto/expense.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable()
export class ExpenseWsDao extends WsDao<ExpenseDto> {
    static WS = "expenses";

    public getById(serverConnection: ServerConnection, id: string): Promise<ExpenseDto> {
        throw new NotImplementedError();
    }

    public save(serverConnection: ServerConnection, expenseDto: ExpenseDto): Promise<ExpenseDto> {
        return new Promise<ExpenseDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            if (!isNullOrEmpty(expenseDto.id)) {
                tokens.set("id", expenseDto.id);

                serverConnection.put(this.constructor.name, ExpenseWsDao.WS + "/:id", tokens, expenseDto.toBody())
                    .then(response => {
                        resolve(expenseDto);
                    })
                    .catch(reason => {
                        reject(reason);
                    });
            } else {
                serverConnection.post(this.constructor.name, ExpenseWsDao.WS + "", tokens, expenseDto.toBody())
                    .then(response => {
                        expenseDto.id = response.headers.get(XVEGAID);
                        resolve(expenseDto);
                    })
                    .catch(reason => {
                        reject(reason);
                    });
            }
        });
    }
}
