import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
    {
        path: "about/:appName",
        loadChildren: () => import("../gyzmo-commons/pages/about/about.module").then(m => m.AboutPageModule),
    },
    {
        path: "cgu", loadChildren: () => import("./account/cgu/cgu.module").then(m => m.CguPageModule),
    },
    {
        path: "privacy-policy", loadChildren: () => import("./account/privacy-policy/privacy-policy.module").then(m => m.PrivacyPolicyPageModule),
    },
    {
        path: "connection",
        loadChildren: () => import("./account/connection/connection.module").then(m => m.ConnectionPageModule),
    },
    {
        path: "order-list",
        loadChildren: () => import("./orders/order-list/order-list.module").then(m => m.OrderPageModule),
    },
    {
        path: "order-lines",
        loadChildren: () => import("./orders/order-lines/order-lines.module").then(m => m.OrderLinesPageModule),
    },
    {
        path: "order-details",
        loadChildren: () => import("./orders/order-details/order-details.module").then(m => m.OrderDetailsPageModule),
    },
    {
        path: "departure",
        loadChildren: () => import("./departure/departure.module").then(m => m.DeparturePageModule),
    },
    {
        path: "delivery-list",
        loadChildren: () => import("./delivery/delivery-list/delivery-list.module").then(m => m.DeliveryPageModule),
    },
    {
        path: "delivery-details",
        loadChildren: () => import("./delivery/delivery-details/delivery-details.module").then(m => m.DeliveryDetailsPageModule),
    },
    {
        path: "delivery-state",
        loadChildren: () => import("./delivery/delivery-state/delivery-state.module").then(m => m.DeliveryStatePopoverModule),
    },
    {
        path: "return",
        loadChildren: () => import("./return/return.module").then(m => m.ReturnPageModule),
    },
    {
        path: "return-check",
        loadChildren: () => import("./return-check/return-check.module").then(m => m.ReturnCheckPageModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            onSameUrlNavigation: "reload",
            initialNavigation: "disabled",
            scrollPositionRestoration: "enabled",
            useHash: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
