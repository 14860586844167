import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { IonicSlides, NavParams, PopoverController } from "@ionic/angular";
import { DateTime } from "luxon";
import { DateHelper } from "../../../helpers/date.helper";
import { HOUR_FORMAT } from "../../../interfaces/constants";

import { DateProvider } from "../../../interfaces/dateProvider";

export interface TimePickerConfig {
    minTime?: string, // default '00:00:00'
    timeFormat?: string, // default 'hh:mm A'
    setLabel?: string, // default 'Set'
    closeLabel?: string, // default 'Close'
    titleLabel?: string, // default 'Time'
    clearButton?: boolean, // default true
    btnCloseSetInReverse?: boolean, // default false
    momentLocale?: string, //  default 'en-US',
    minutesSteps?: number; // default 1
}

@Component({
    selector: "time-picker-popover",
    templateUrl: "time-picker-popover.component.html",
    styleUrls: ["time-picker-popover.component.scss"],
})
export class TimePickerPopoverComponent {
    swiperModules = [IonicSlides];

    @ViewChild("sliderHours", { static: false }) sliderHours: ElementRef | undefined;
    @ViewChild("sliderMinutes", { static: false }) sliderMinutes: ElementRef | undefined;
    @ViewChild("sliderSeconds", { static: false }) sliderSeconds: ElementRef | undefined;
    @ViewChild("sliderMeridian", { static: false }) sliderMeridian: ElementRef | undefined;

    @Input() showDebugInfo = false;

    hoursArray: string[] = [];
    minutesArray: string[] = [];
    secondsArray: string[] = [];
    meridianArray: string[] = [];

    sliderHoursActiveIndex = 0;
    sliderMinutesActiveIndex = 0;
    sliderSecondsActiveIndex = 0;
    sliderMeridianActiveIndex = 0;

    dateTime: DateTime;
    mainConfig: TimePickerConfig = {};

    @Input() selectedTime: any;
    @Input() config: TimePickerConfig;

    constructor(private navParams: NavParams,
                private popoverController: PopoverController,
                private dateProvider: DateProvider) {
    }

    async ionViewWillEnter() {
        await this.initTimePicker();
    }

    async initTimePicker() {
        if (this.navParams.get("selectedTime")) {
            this.selectedTime = this.navParams.get("selectedTime") as string;
        }
        this.mainConfig = this.mergeConfig(<TimePickerConfig>this.navParams.get("config"));

        await this.setHoursArray(this.mainConfig.timeFormat);
        await this.setMinutesArray(this.mainConfig.timeFormat, this.sliderHoursActiveIndex == 0);
        await this.setSecondsArray(this.mainConfig.timeFormat);
        await this.setMeridianArray(this.mainConfig.timeFormat);
    }

    // get slider hours active index
    async onChangeHoursSlide(event) {
        this.sliderHoursActiveIndex = event.target.swiper.realIndex;

        await this.setMinutesArray(this.mainConfig.timeFormat, this.sliderHoursActiveIndex == 0);
    }

    // get slider minutes active index
    onChangeMinutesSlide(event) {
        this.sliderMinutesActiveIndex = event.target.swiper.realIndex;
    }

    // get slider seconds active index
    onChangeSecondsSlide(event) {
        this.sliderSecondsActiveIndex = event.target.swiper.realIndex;
    }

    // get slider seconds active index
    onChangeMeridianSlide(event) {
        this.sliderMeridianActiveIndex = event.target.swiper.realIndex;
    }

    // initialize timepicker Object
    mergeConfig(config: TimePickerConfig) {
        const objConfig: TimePickerConfig = {};
        objConfig.momentLocale = config.momentLocale ? config.momentLocale : "en-US";
        //moment.locale(objConfig.momentLocale);

        objConfig.timeFormat = config.timeFormat ? config.timeFormat : "hh:mm A";
        if (typeof (this.selectedTime) === "string") {
            this.dateTime = this.selectedTime ?
                            DateHelper.tryFromFormat(this.selectedTime, objConfig.timeFormat) :
                            this.dateProvider.now();
        } else {
            this.dateTime = this.selectedTime ?
                            DateHelper.tryFromFormat(this.selectedTime, HOUR_FORMAT) :
                            this.dateProvider.now();
        }

        objConfig.minTime = config.minTime ? config.minTime : "00:00:00";
        objConfig.setLabel = config.setLabel ? config.setLabel : "Set";
        objConfig.closeLabel = config.closeLabel ? config.closeLabel : "Close";
        objConfig.titleLabel = config.titleLabel ? config.titleLabel : "Time";
        objConfig.btnCloseSetInReverse = config.btnCloseSetInReverse ? config.btnCloseSetInReverse : false;
        objConfig.minutesSteps = config.minutesSteps ? config.minutesSteps : 1;

        return objConfig;
    }

    // close modal
    async closeTimePickerPopover() {
        const modal = await this.popoverController.getTop();
        await modal.dismiss("");
    }

    // set time in modal dismiss method
    async validateTimePickerPopover() {
        const modal = await this.popoverController.getTop();
        this.selectedTime = this.setTimeInMomentObject();
        await modal.dismiss({ time: (this.selectedTime) });
    }

    // set time in moment object
    setTimeInMomentObject() {
        const formatArray = [];
        const timeArray = [];

        if (this.mainConfig.timeFormat.indexOf("H") >= 0) {
            formatArray.push("H");
            const hourString = this.hoursArray[this.sliderHoursActiveIndex];
            timeArray.push(hourString);
        }
        if (this.mainConfig.timeFormat.indexOf("h") >= 0) {
            formatArray.push("h");
            const hourString = this.hoursArray[this.sliderHoursActiveIndex];
            timeArray.push(hourString);
        }
        if (this.mainConfig.timeFormat.indexOf("k") >= 0) {
            formatArray.push("k");
            const hourString = this.hoursArray[this.sliderHoursActiveIndex];
            timeArray.push(hourString);
        }
        if (this.mainConfig.timeFormat.indexOf("m") >= 0) {
            formatArray.push("m");
            const minString = this.minutesArray[this.sliderMinutesActiveIndex];
            timeArray.push(minString);
        }
        if (this.mainConfig.timeFormat.indexOf("s") >= 0) {
            formatArray.push("s");
            const secString = this.secondsArray[this.sliderSecondsActiveIndex];
            timeArray.push(secString);
        }
        if (this.mainConfig.timeFormat.indexOf("a") >= 0 || this.mainConfig.timeFormat.indexOf("A") >= 0) {
            formatArray.push("a");
            const meridianString = this.meridianArray[this.sliderMeridianActiveIndex].toLowerCase();
            timeArray.push(meridianString);
        }

        const formatString = formatArray.join(":");
        const timeString = timeArray.join(":");

        const mmObj = DateHelper.tryFromFormat(timeString, formatString);
        return mmObj.toFormat(this.mainConfig.timeFormat);
    }

    // sets hours array
    async setHoursArray(timeFormat: string) {
        let minHour = DateHelper.tryFromFormat(this.mainConfig.minTime, this.mainConfig.timeFormat).hour;

        let initialSlide = -1;
        const obj = this.dateProvider.now().startOf("day").plus({ hours: minHour });
        if (timeFormat.indexOf("HH") >= 0) {
            this.hoursArray = this.initHoursArray(obj, minHour, 23, "HH");
            initialSlide = this.hoursArray.findIndex(element => {
                return element == this.dateTime.toFormat("HH");
            });

        } else if (timeFormat.indexOf("H") >= 0) {
            this.hoursArray = this.initHoursArray(obj, minHour, 23, "H");
            initialSlide = this.hoursArray.findIndex(element => {
                return element == this.dateTime.toFormat("H");
            });

        } else if (timeFormat.indexOf("hh") >= 0) {
            this.hoursArray = this.initHoursArray(obj.plus({ hours: 1 }), minHour, 11, "hh");
            initialSlide = this.hoursArray.findIndex(element => {
                return element == this.dateTime.toFormat("hh");
            }) - 1;

        } else if (timeFormat.indexOf("h") >= 0) {
            this.hoursArray = this.initHoursArray(obj.plus({ hours: 1 }), minHour, 11, "h");
            initialSlide = this.hoursArray.findIndex(element => {
                return element == this.dateTime.toFormat("h");
            }) - 1;

        } else if (timeFormat.indexOf("kk") >= 0) {
            this.hoursArray = this.initHoursArray(obj.plus({ hours: 1 }), minHour, 23, "kk");
            initialSlide = this.hoursArray.findIndex(element => {
                return element == this.dateTime.toFormat("kk");
            }) - 1;

        } else if (timeFormat.indexOf("k") >= 0) {
            this.hoursArray = this.initHoursArray(obj.plus({ hours: 1 }), minHour, 23, "k");
            initialSlide = this.hoursArray.findIndex(element => {
                return element == this.dateTime.toFormat("k");
            }) - 1;
        }

        if (initialSlide >= 0) {
            this.sliderHoursActiveIndex = initialSlide;
            await this.sliderHours.nativeElement.swiper.slideTo(initialSlide, 0, false);
        } else {
            this.sliderHoursActiveIndex = 0;
            await this.sliderHours.nativeElement.swiper.slideTo(0, 0, false);
        }
    }

    // Initialize hours array
    initHoursArray(date: DateTime, start: number, end: number, format: string) {
        const hoursArray: any = [];
        for (let i = start; i <= end; i++) {
            hoursArray.push(date.toFormat(format));
            date = date.plus({ hours: 1 });
        }
        return hoursArray;
    }

    // sets minutes array
    async setMinutesArray(timeFormat: string, setMin = false) {
        let minMinutes = DateHelper.tryFromFormat(this.mainConfig.minTime, this.mainConfig.timeFormat).minute;

        let initialSlide = -1;
        let date = this.dateProvider.now().startOf("day");
        if (setMin) {
            date = date.plus({ minutes: minMinutes });
        }

        if (timeFormat.indexOf("mm") >= 0) {
            this.minutesArray = this.initMinutesArray(date, setMin ? minMinutes : 0, this.mainConfig.minutesSteps, "mm");
            initialSlide = this.minutesArray.findIndex(element => {
                return element == this.dateTime.toFormat("mm");
            });
        } else if (timeFormat.indexOf("m") >= 0) {
            this.minutesArray = this.initMinutesArray(date, setMin ? minMinutes : 0, this.mainConfig.minutesSteps, "m");
            initialSlide = this.minutesArray.findIndex(element => {
                return element == this.dateTime.toFormat("m");
            });
        }

        if (initialSlide >= 0) {
            this.sliderMinutesActiveIndex = initialSlide;
            await this.sliderMinutes.nativeElement.swiper.slideTo(initialSlide, 0, false);
        } else {
            this.sliderMinutesActiveIndex = 0;
            await this.sliderMinutes.nativeElement.swiper.slideTo(0, 0, false);
        }
    }

    // initialize minutes array
    initMinutesArray(date: DateTime, start: number, step: number, format: string) {
        const minutesArray: any = [];
        for (let i = start; i < 60; i = i + step) {
            minutesArray.push(date.toFormat(format));
            date = date.plus({ minutes: step });
        }

        return minutesArray;
    }

    // sets seconds array
    async setSecondsArray(timeFormat: string) {
        let initialSlide = -1;
        if (timeFormat.indexOf("ss") >= 0) {
            this.secondsArray = this.initSecondsArray("ss");
            initialSlide = Number(this.dateTime.toFormat("ss"));
        } else if (timeFormat.indexOf("s") >= 0) {
            this.secondsArray = this.initSecondsArray("s");
            initialSlide = Number(this.dateTime.toFormat("s"));
        }

        if (initialSlide >= 0) {
            this.sliderSecondsActiveIndex = initialSlide;
            await this.sliderSeconds.nativeElement.swiper.slideTo(initialSlide, 0, false);
        } else {
            this.sliderSecondsActiveIndex = 0;
            await this.sliderSeconds.nativeElement.swiper.slideTo(0, 0, false);
        }
    }

    // initialize seconds array
    initSecondsArray(format: string) {
        let date = this.dateProvider.now().startOf("minute");
        const secondsArray: any = [];
        for (let i = 0; i < 60; i++) {
            secondsArray.push(date.toFormat(format));
            date = date.plus({ seconds: 1 });
        }
        return secondsArray;
    }

    // set meridian array
    async setMeridianArray(timeFormat: string) {
        if (timeFormat.indexOf("a") >= 0 || timeFormat.indexOf("A") >= 0) {
            const format = timeFormat.indexOf("a") >= 0 ? "a" : "A";
            const obj = this.dateProvider.now().startOf("day");
            this.meridianArray.push(obj.toFormat(format));
            this.meridianArray.push(obj.plus({ hours: 12 }).toFormat(format));
        }

        let initialSlide = -1;
        if (timeFormat.indexOf("a") >= 0) {
            initialSlide = this.dateTime.toFormat("a") === "am" ? 0 : 1;
        } else if (timeFormat.indexOf("A") >= 0) {
            initialSlide = this.dateTime.toFormat("A") === "AM" ? 0 : 1;
        }

        if (initialSlide >= 0) {
            this.sliderMeridianActiveIndex = initialSlide;
            await this.sliderMeridian.nativeElement.swiper.slideTo(initialSlide, 0, false);
        } else {
            this.sliderMeridianActiveIndex = 0;
            await this.sliderMeridian.nativeElement.swiper.slideTo(0, 0, false);
        }
    }
}



