import { Injectable } from "@angular/core";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/NotImplementedError";
import { ActivityDto } from "../../dto/activity.dto";
import { WorkflowDto } from "../../dto/workflow.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class WorkflowWsDao extends WsDao<WorkflowDto> {
    static WS = "workflows";
    static WS_ACTI = "activities";

    public getById(serverConnection: ServerConnection, id: string): Promise<WorkflowDto> {
        throw new NotImplementedError();
    }

    public getParametersForWorkflow(serverConnection: ServerConnection, activityId: string): Promise<ActivityDto> {
        return new Promise<ActivityDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", activityId);

            serverConnection.get(this.constructor.name, WorkflowWsDao.WS + "/" + WorkflowWsDao.WS_ACTI + "/:id", tokens)
                .then(response => {
                    resolve(ActivityDto.fromBody(response.body));
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, modelDto: WorkflowDto): Promise<WorkflowDto> {

        // @ts-ignore
        modelDto["activity"] = { id: modelDto.activity };

        // @ts-ignore
        modelDto["recipient"] = { id: modelDto.recipient };

        // @ts-ignore
        modelDto["type"] = { id: modelDto.type };

        // @ts-ignore
        modelDto["priority"] = { id: modelDto.priority };

        // @ts-ignore
        modelDto["company"] = { id: modelDto.company };

        // @ts-ignore
        modelDto["frequency"] = { id: modelDto.frequency };

        // @ts-ignore
        modelDto["sender"] = { id: modelDto.sender };

        return new Promise<WorkflowDto>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.post(this.constructor.name, WorkflowWsDao.WS, tokens, modelDto.toBody())
                .then(response => {
                    resolve(modelDto);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
