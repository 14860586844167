import { Injectable } from "@angular/core";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/NotImplementedError";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { DeliveryLinkedMovementDto } from "../../dto/deliveryLinkedMovement.dto";
import { OrderLinkedMovementDto } from "../../dto/orderLinkedMovement.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class LinkedMovementWsDao extends WsDao<any> {
    static WS = "movements";

    public getById(serverConnection: ServerConnection, id: string): Promise<any> {
        throw new NotImplementedError();
    }

    public save(serverConnection: ServerConnection, value: any): Promise<any> {
        throw new NotImplementedError();
    }

    public saveOrderLinkedMovement(serverConnection: ServerConnection, linkedMovementDto: OrderLinkedMovementDto): Promise<OrderLinkedMovementDto> {
        return new Promise<OrderLinkedMovementDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", linkedMovementDto.movementId);
            tokens.set("idLinkedMovement", linkedMovementDto.id);

            let urlPattern = LinkedMovementWsDao.WS + "/:id/linked-movements/:idLinkedMovement?origin=son";
            if (!isNullOrEmpty(linkedMovementDto.equipment.id)) {
                urlPattern += "&affectEquipment=true";
            }

            serverConnection.put(this.constructor.name, urlPattern, tokens, linkedMovementDto.toBody())
                .then(response => {
                    resolve(linkedMovementDto);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public saveDeliveryLinkedMovement(serverConnection: ServerConnection, linkedMovementDto: DeliveryLinkedMovementDto): Promise<DeliveryLinkedMovementDto> {
        return new Promise<DeliveryLinkedMovementDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", linkedMovementDto.movementId);
            tokens.set("idLinkedMovement", linkedMovementDto.id);

            serverConnection.put(this.constructor.name, LinkedMovementWsDao.WS + "/:id/linked-movements/:idLinkedMovement?origin=son", tokens, linkedMovementDto.toBody())
                .then(response => {
                    resolve(linkedMovementDto);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
