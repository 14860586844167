import { DateTime } from "luxon";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { LtcStatuses } from "../interfaces/ltcStatuses";
import { Order } from "../models/order.model";
import { BaseDto } from "./base/base.dto";
import { InvoiceDto } from "./invoice.dto";
import { LocationDto } from "./location.dto";
import { MovementDto } from "./movement.dto";
import { OrderLinkedMovementDto } from "./orderLinkedMovement.dto";
import { ThirdPartyDto } from "./thirdParty.dto";

export class OrderDto extends BaseDto<Order> {
    id = "";
    wording: string = "";
    startDate: DateTime = null;
    plannedReturnDate: DateTime = null;
    orderDate: DateTime = null;
    returnDate: DateTime = null;
    wantedDays: number = 0;
    duration: number = 0;
    cumulativeMileage: number = 0;
    includedMileage: number = 0;
    contractNumber: string = "";
    bookingCode: string = "";
    status: LtcStatuses = LtcStatuses.CANCELLED;
    isClosed: boolean = false;
    // Fks
    customer: ThirdPartyDto;
    driver: ThirdPartyDto;
    merchant: ThirdPartyDto;

    movement: MovementDto;
    linkedMovements: OrderLinkedMovementDto[] = [];

    invoice: InvoiceDto;

    startLocation: LocationDto;
    plannedReturnLocation: LocationDto;
    returnLocation: LocationDto;

    public isStillDownloading: boolean = false;

    constructor() {
        super();
        this.customer = new ThirdPartyDto();
        this.driver = new ThirdPartyDto();
        this.merchant = new ThirdPartyDto();

        this.movement = new MovementDto();

        this.invoice = new InvoiceDto();

        this.startLocation = new LocationDto();
        this.plannedReturnLocation = new LocationDto();
        this.returnLocation = new LocationDto();

        this.status = LtcStatuses.RESERVATION;
    }

    public static fromBody(body: any, isStillDownloading: boolean): OrderDto {
        let orderDto = new OrderDto();

        orderDto.id = body.id;
        orderDto.wording = body.wording;
        orderDto.startDate = DateHelper.tryFromFormat(body.startDate, DATE_NODEJS_FORMAT);
        orderDto.orderDate = DateHelper.tryFromFormat(body.orderDate, DATE_NODEJS_FORMAT);
        orderDto.plannedReturnDate = DateHelper.tryFromFormat(body.plannedReturnDate, DATE_NODEJS_FORMAT);
        orderDto.returnDate = DateHelper.tryFromFormat(body.returnDate, DATE_NODEJS_FORMAT);
        orderDto.wantedDays = body.wantedDays;
        orderDto.duration = body.duration;
        orderDto.cumulativeMileage = body.cumulativeMileage;
        orderDto.includedMileage = body.includedMileage;
        orderDto.contractNumber = body.contractNumber;
        orderDto.bookingCode = body.bookingCode;
        orderDto.isClosed = body.isClosed;

        orderDto.isStillDownloading = isStillDownloading;

        if (body.customer) {
            orderDto.customer = ThirdPartyDto.fromBody(body.customer);
        }
        if (body.driver) {
            orderDto.driver = ThirdPartyDto.fromBody(body.driver);
        }
        if (body.merchant) {
            orderDto.merchant = ThirdPartyDto.fromBody(body.merchant);
        }

        if (body.movement) {
            orderDto.movement = MovementDto.fromBody(body.movement);
        }

        if (body.event) {
            orderDto.invoice = InvoiceDto.fromBody(body.event);
        }

        if (body.startLocation) {
            orderDto.startLocation = LocationDto.fromBody(body.startLocation);
        }
        if (body.plannedReturnLocation) {
            orderDto.plannedReturnLocation = LocationDto.fromBody(body.plannedReturnLocation);
        }
        if (body.returnLocation) {
            orderDto.returnLocation = LocationDto.fromBody(body.returnLocation);
        }

        if (body.status) {
            orderDto.status = body.status.id;
        }

        return orderDto;
    }

    static fromModel(order: Order, isStillDownloading: boolean): OrderDto {
        let orderDto = new OrderDto();
        orderDto.id = order.id;
        orderDto.wording = order.wording;
        orderDto.startDate = DateHelper.tryFromISO(order.startDate);
        orderDto.orderDate = DateHelper.tryFromISO(order.orderDate);
        orderDto.plannedReturnDate = DateHelper.tryFromISO(order.plannedReturnDate);
        orderDto.returnDate = DateHelper.tryFromISO(order.returnDate);
        orderDto.wantedDays = order.wantedDays;
        orderDto.duration = order.duration;
        orderDto.cumulativeMileage = order.cumulativeMileage;
        orderDto.includedMileage = order.includedMileage;
        orderDto.contractNumber = order.contractNumber;
        orderDto.bookingCode = order.bookingCode;
        orderDto.status = order.status;
        orderDto.isClosed = (order.isClosed == 1);

        orderDto.isStillDownloading = isStillDownloading;

        // Fks
        if (order.customer) {
            orderDto.customer = ThirdPartyDto.fromModel(order.customer);
        }
        if (order.driver) {
            orderDto.driver = ThirdPartyDto.fromModel(order.driver);
        }
        if (order.merchant) {
            orderDto.merchant = ThirdPartyDto.fromModel(order.merchant);
        }

        if (order.movement) {
            orderDto.movement = MovementDto.fromModel(order.movement);
        }

        if (order.invoice) {
            orderDto.invoice = InvoiceDto.fromModel(order.invoice);
        }

        if (order.startLocation) {
            orderDto.startLocation = LocationDto.fromModel(order.startLocation);
        }
        if (order.plannedReturnLocation) {
            orderDto.plannedReturnLocation = LocationDto.fromModel(order.plannedReturnLocation);
        }
        if (order.returnLocation) {
            orderDto.returnLocation = LocationDto.fromModel(order.returnLocation);
        }

        orderDto.linkedMovements = [];
        if (order.linkedMovements) {
            order.linkedMovements.forEach(linkedMovement => {
                orderDto.linkedMovements.push(OrderLinkedMovementDto.fromModel(linkedMovement));
            });
        }

        return orderDto;
    }

    static fromOrderZoom(value: any) {
        let orderDto = new OrderDto();
        orderDto.id = value.field0;

        // Model "Hors catalogue"
        orderDto.wording = value.field14;

        if (value.field1) {
            orderDto.startDate = DateHelper.tryFromFormat(value.field1, DATE_NODEJS_FORMAT);
        }
        orderDto.status = value.field10;
        orderDto.invoice.orderPicker.id = value.field11;

        return orderDto;
    }

    toBody() {
        let clone: OrderDto = this.clone();

        // Modifier l'object avant envoi
        // @ts-ignore
        clone.status = { id: this.status };
        delete clone.customer;
        delete clone.driver;
        delete clone.merchant;
        delete clone.movement;
        delete clone.startLocation;
        delete clone.returnLocation;
        delete clone.plannedReturnLocation;

        return clone;
    }

    public toModel(): Order {
        let order = new Order();

        order.id = this.id;
        order.wording = this.wording;
        order.startDate = DateHelper.tryToISO(this.startDate);
        order.orderDate = DateHelper.tryToISO(this.orderDate);
        order.plannedReturnDate = DateHelper.tryToISO(this.plannedReturnDate);
        order.returnDate = DateHelper.tryToISO(this.returnDate);
        order.wantedDays = this.wantedDays;
        order.duration = this.duration;
        order.cumulativeMileage = this.cumulativeMileage;
        order.includedMileage = this.includedMileage;
        order.contractNumber = this.contractNumber;
        order.bookingCode = this.bookingCode;
        order.status = this.status;
        order.isClosed = (this.isClosed ? 1 : 0);

        // Fks
        if (this.customer) {
            order.customer = this.customer.toModel();
        }
        if (this.driver) {
            order.driver = this.driver.toModel();
        }
        if (this.merchant) {
            order.merchant = this.merchant.toModel();
        }

        if (this.movement) {
            order.movement = this.movement.toModel();
        }

        if (this.invoice) {
            order.invoice = this.invoice.toModel();
        }

        if (this.startLocation) {
            order.startLocation = this.startLocation.toModel();
        }
        if (this.plannedReturnLocation) {
            order.plannedReturnLocation = this.plannedReturnLocation.toModel();
        }
        if (this.returnLocation) {
            order.returnLocation = this.returnLocation.toModel();
        }

        order.linkedMovements = [];
        this.linkedMovements.forEach(linkedMovement => {
            order.linkedMovements.push(linkedMovement.toModel());
        });

        return order;
    }

    public isOnDay(activeDay: DateTime): boolean {
        return DateHelper.isSameDay(this.startDate, activeDay);
    }
}
