import { Injectable } from "@angular/core";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/NotImplementedError";
import { AddressDto } from "../../dto/address.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class AddressWsDao extends WsDao<AddressDto> {
    static WS = "addresses";

    public getById(serverConnection: ServerConnection, id: string): Promise<AddressDto> {
        return new Promise<AddressDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, AddressWsDao.WS + "/:id", tokens)
                .then(response => {
                    let company = AddressDto.fromBody(response.body);
                    resolve(company);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, addressDto: AddressDto): Promise<AddressDto> {
        throw new NotImplementedError();
    }
}
