import { isNullOrEmpty } from "../../../helpers/null.helper";
import { SqlHelper } from "../../../helpers/sql.helper";
import { ModelBase } from "../../../models/base/model.base";
import { LoggerService } from "../../../services/logs/logger.service";

export abstract class DbDaoBase<T extends ModelBase> {
    protected constructor(protected logger: LoggerService) {
    }

    public abstract createTable(): Promise<void>;

    public abstract createIndexes(): Promise<void>;

    public abstract get(id: string, hydrate: boolean): Promise<T>;

    public abstract getTableName(): string;

    public abstract deleteAll(): Promise<any>;

    public abstract delete(id: string): Promise<any>;

    public abstract save(entity: T): Promise<T>;

    protected abstract rowToModel(row: any): T;

    protected getValue(param: string | number, lastParam: boolean = false): string {
        let result = "";

        param = "" + param;

        if (isNullOrEmpty(param) || param == "null" || param == "undefined") {
            result += "''";
        } else {
            result += "'" + this.escapeQuotes(param) + "'";
        }

        if (!lastParam) {
            result += ",";
        }

        return result;
    }

    protected getFkValue(param: { id: string | number }, lastParam: boolean = false): string {
        let result = "";

        if (param == null) {
            result += "''";
        } else {
            result += "'" + param.id + "'";
        }

        if (!lastParam) {
            result += ",";
        }

        return result;
    }

    protected getValueAsJsonString(param: any, lastParam: boolean = false): string {
        let result = "";

        let clonedParam = JSON.parse(JSON.stringify(param));
        if (clonedParam) {
            delete clonedParam.tablename;
        }

        if (isNullOrEmpty(clonedParam) || clonedParam == "null" || clonedParam == "undefined") {
            result += "''";
        } else {
            result += "'" + this.escapeQuotesForJson(JSON.stringify(clonedParam)) + "'";
        }

        if (!lastParam) {
            result += ",";
        }

        return result;
    }

    protected logSqlError(error: any) {
        this.logger.error(this.constructor.name, SqlHelper.stringifySqlError(error));
    }

    // https://gist.github.com/getify/3667624
    private escapeQuotes(str): string {
        return str.replace(/[\\']/g, "''");
    }

    // https://gist.github.com/getify/3667624
    private escapeQuotesForJson(str): string {
        return str.replace(/'/g, "''").replace(/"/g, "\"");
    }
}
