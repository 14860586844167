import { SQLite, SQLiteObject } from "@awesome-cordova-plugins/sqlite/ngx";
import { DeviceHelper, PlatformName } from "../helpers/device.helper";
import { SqlHelper } from "../helpers/sql.helper";
import { DatabaseLocation } from "../interfaces/databaseLocation";
import { SQLiteResult, SqlProvider } from "../interfaces/sqlProvider";
import { LoggerService } from "../services/logs/logger.service";

export class NativeCipherSqlProvider implements SqlProvider {
    public dbName: string;
    private _db: SQLiteObject;
    private initialized: boolean = false;
    private bulkMode = false;
    private bulkQueries: string[] = [];

    public constructor(private logger: LoggerService,
                       private deviceHelper: DeviceHelper,
                       private sqlite: SQLite,
                       private key) {
    }

    public getDbName(): string {
        return this.dbName;
    }

    public isInitialized() {
        return this.initialized;
    }

    public async initialize(dbName: string,
                            location: DatabaseLocation): Promise<boolean> {
        await this.tryDeleteNonCipherDatabase(dbName, location);

        let cipherDbName = dbName + "-cipher";

        return new Promise<boolean>(resolve => {
            this.logger.debug(this.constructor.name, "Opening database " + cipherDbName + " on location " + location);

            if (this.deviceHelper.getPlatform() == PlatformName.IOS && location != DatabaseLocation.default) {
                this.sqlite.create({
                    name: cipherDbName,
                    iosDatabaseLocation: location,
                    key: this.key,
                })
                    .then((db: SQLiteObject) => {
                        this.logger.debug(this.constructor.name, "Opening database success : " + cipherDbName);
                        this._db = db;
                        this.initialized = true;
                        this.dbName = cipherDbName;
                        resolve(true);
                    })
                    .catch(e => {
                        this.logger.error(this.constructor.name, "Opening database failed : " + cipherDbName, "error : " + SqlHelper.stringifySqlError(e));
                        this.initialized = false;
                        this.dbName = "";
                        resolve(false);
                    });
            } else {
                this.sqlite.create({
                    name: cipherDbName,
                    location: location,
                    key: this.key,
                })
                    .then((db: SQLiteObject) => {
                        this.logger.debug(this.constructor.name, "Opening database success : " + cipherDbName);
                        this._db = db;
                        this.initialized = true;
                        this.dbName = cipherDbName;
                        resolve(true);
                    })
                    .catch(e => {
                        this.logger.error(this.constructor.name, "Opening database failed : " + cipherDbName, "error : " + SqlHelper.stringifySqlError(e));
                        this.initialized = false;
                        this.dbName = "";
                        resolve(false);
                    });
            }
        });
    }

    public close(): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this.logger.debug(this.constructor.name, "Closing database " + this.dbName);

            if (!this.isInitialized()) {
                this.logger.debug(this.constructor.name, "No database to close");
                resolve(true);
            } else {
                this._db.abortallPendingTransactions();
                this._db.close()
                    .then(() => {
                        this.logger.debug(this.constructor.name, "Closing database success : " + this.dbName);
                        this.initialized = false;
                        this.dbName = "";
                        resolve(true);
                    })
                    .catch(e => {
                        this.logger.debug(this.constructor.name, "Closing database failed : " + this.dbName, "error : " + e);
                        this.initialized = false;
                        this.dbName = "";
                        resolve(false);
                    });
            }
        });
    }

    public query(queryText: string, bulkable = true): Promise<SQLiteResult> {
        return new Promise((resolve, reject) => {
            if (bulkable && this.bulkMode && (queryText.toUpperCase().startsWith("INSERT") || queryText.toUpperCase().startsWith("DELETE"))) {
                this.logger.debug(this.constructor.name, queryText + " as been bulked.");
                this.bulkQueries.push(queryText);
                resolve(new SQLiteResult(true, null));
            } else {
                let t0 = performance.now();

                this._db.executeSql(queryText, [])
                    .then((res: any) => {
                        let result = this.toSqliteResult(res);

                        let t1 = performance.now();
                        this.logger.debug(this.constructor.name,
                            "Database=" + this.dbName,
                            "Query=\"" + queryText + "\"",
                            "Result=" + SqlHelper.stringifySqlResult(result),
                            "[" + Math.floor(t1 - t0) + "ms]");

                        if (Math.floor(t1 - t0) > 1500) {
                            this.logger.warn(this.constructor.name,
                                "Long queryText, need optimisation ? [" + Math.floor(t1 - t0) + "ms]",
                                "Database=" + this.dbName,
                                "Query=\"" + queryText + "\"");
                        }

                        resolve(result);
                    })
                    .catch(err => {
                        this.logger.error(this.constructor.name,
                            "Database=" + this.dbName,
                            "Query=\"" + queryText + "\"",
                            "Result=" + SqlHelper.stringifySqlError(err));
                        reject(new SQLiteResult(false, null, SqlHelper.stringifySqlError(err)));
                    });
            }
        });
    }

    public dropDatabase(): Promise<void> {
        this.logger.debug(this.constructor.name, "dropDatabase");

        return new Promise<void>(resolve => {
            return this.query(`SELECT name
                               FROM sqlite_master
                               WHERE type = 'table'`)
                .then(async data => {
                    if (data.rows.length > 0) {
                        for (let i = 0; i < data.rows.length; i++) {
                            // TODO - clean parameters
                            if (data.rows[i].name !== "__WebKitDatabaseInfoTable__") {
                                await this.query(`DROP TABLE ${ data.rows[i].name }`);
                            }
                        }
                    }

                    resolve();
                });
        });
    }

    public async getExistingTables(): Promise<string[]> {
        this.logger.info(this.constructor.name, "getExistingTables");

        let data = await this.query("SELECT name FROM sqlite_master WHERE type = 'table'");
        let result = [];

        if (data.rows.length > 0) {
            for (let i = 0; i < data.rows.length; i++) {
                if (data.rows[i].name !== "__WebKitDatabaseInfoTable__") {
                    result.push(data.rows[i].name);
                }
            }
        }

        return result;
    }

    public enableBulkWriting() {
        this.logger.warn(this.constructor.name, "Enabling bulk mode !");
        this.bulkMode = true;
    }

    public disableBulkWriting() {
        this.logger.warn(this.constructor.name, "Disabling bulk mode ! Dropping all cached queries !");
        this.bulkQueries = [];
        this.bulkMode = false;
    }

    public async commitBulk() {
        this.logger.warn(this.constructor.name, "Committing all bulked queries !");

        this.bulkMode = false;
        await this.commitBulkQueries(this.bulkQueries);
        this.bulkQueries = [];
    }

    private async tryDeleteNonCipherDatabase(dbName: string, location: DatabaseLocation): Promise<void> {
        this.logger.debug(this.constructor.name, "Trying to delete non cipher database " + dbName + " on location " + location);

        if (this.deviceHelper.getPlatform() == PlatformName.IOS && location != DatabaseLocation.default) {
            await this.sqlite.deleteDatabase({
                name: dbName,
                iosDatabaseLocation: location,
            })
                .catch(e => {
                    this.logger.error(this.constructor.name, e);
                });
        } else {
            await this.sqlite.deleteDatabase({
                name: dbName,
                location: location,
            })
                .catch(e => {
                    this.logger.error(this.constructor.name, e);
                });
        }
    }

    private commitBulkQueries(queries: string[]): Promise<void> {
        let t0 = performance.now();

        return new Promise((resolve, reject) => {
            void this._db.sqlBatch(queries).then(() => {
                let t1 = performance.now();
                this.logger.debug(this.constructor.name, "bulkQuery [" + Math.floor(t1 - t0) + "ms]");
                resolve();
            }).catch(reason => {
                reject({ err: reason });
            });
        });
    }

    private toSqliteResult(res: any): SQLiteResult {
        let result = new SQLiteResult(true, []);

        for (let i = 0; i < res.rows.length; i++) {
            result.rows.push(res.rows.item(i));
        }

        if (res.rowsAffected > 0) {
            try {
                result.insertId = res.insertId;
            } catch { /* empty */
            }
        }

        return result;
    }
}
