import { Injectable } from "@angular/core";
import { CacheService } from "../../gyzmo-commons/services/cache.service";
import { AttachmentDbDao } from "../dao/db/attachment.db.dao";
import { AttachmentWsDao } from "../dao/ws/attachment.ws.dao";
import { AttachmentDto } from "../dto/attachment.dto";
import { AttachmentKinds } from "../interfaces/attachmentKinds";
import { Attachment } from "../models/attachment.model";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class AttachmentService {
    constructor(private attachmentWsDao: AttachmentWsDao,
                private attachmentDbDao: AttachmentDbDao,
                private cacheService: CacheService,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public async cacheByCompanyId(companyId: string) {
        await this.attachmentWsDao.getByCompanyId(this.serversConnectionsProvider.getServerConnection(), companyId);
    }

    public save(attachment: Attachment): Promise<Attachment> {
        let promise = [];

        promise.push(this.attachmentWsDao.save(this.serversConnectionsProvider.getServerConnection(), AttachmentDto.fromModel(attachment))
            .then((updatedAttachment: AttachmentDto) => {
                return this.attachmentDbDao.save(updatedAttachment.toModel());
            }));

        return Promise.all(promise)
            .then(results => {
                return results[0];
            });
    }

    public delete(attachment: AttachmentDto): Promise<void> {
        let promise = [];

        promise.push(this.attachmentWsDao.delete(this.serversConnectionsProvider.getServerConnection(), attachment.id, attachment.object, attachment.key)
            .then(() => {
                return this.attachmentDbDao.delete(attachment.id);
            }));

        return Promise.all(promise)
            .then(results => {
                return;
            });
    }

    public async getCGU(companyId: string): Promise<string> {
        let cacheId = "company/" + companyId + "/" + AttachmentKinds.CGU;
        if (await this.cacheService.isCached(cacheId)) {
            return (await this.cacheService.getCached(cacheId)).value;
        } else {
            return "";
        }
    }

    public async getPrivacyPolicy(companyId: string): Promise<string> {
        let cacheId = "company/" + companyId + "/" + AttachmentKinds.POLITIQUE_CONFID;
        if (await this.cacheService.isCached(cacheId)) {
            return (await this.cacheService.getCached(cacheId)).value;
        } else {
            return "";
        }
    }

    public async getUserManual(): Promise<string> {
        let cacheId = "company/" + "IRIUM" + "/" + AttachmentKinds.MODE_EMPLOI;
        if (await this.cacheService.isCached(cacheId)) {
            return (await this.cacheService.getCached(cacheId)).value;
        } else {
            return "";
        }
    }
}
