import { Injectable } from "@angular/core";
import { UserDto } from "../dto/user.dto";
import { AttachmentService } from "./attachment.service";
import { HourTypeService } from "./hourType.service";
import { ReturnCheckStatusService } from "./returnCheckStatus.service";
import { SurveyKindService } from "./surveyKind.service";

@Injectable({ providedIn: "root" })
export class DefaultDataDownloaderService {
    constructor(private returnCheckStatusService: ReturnCheckStatusService,
                private hourTypeService: HourTypeService,
                private surveyKindService: SurveyKindService,
                private attachmentService: AttachmentService) {
    }

    public async onConnectionDefaultData(user: UserDto): Promise<void> {
        let promises = [];

        promises.push(this.returnCheckStatusService.synchronize());
        promises.push(this.hourTypeService.synchronize());
        promises.push(this.surveyKindService.synchronize());
        promises.push(this.attachmentService.cacheByCompanyId(user.company.id));
        //promises.push(this.attachmentService.cacheByCompanyId("IRIUM"));

        await Promise.all(promises);
    }

    public async checkDefaultData(): Promise<{ correctness: boolean; message: string }> {
        let correctness = await this.returnCheckStatusService.checkData();
        if (!correctness) {
            return { correctness, message: "ReturnCheckStatus vide" };
        }

        correctness = await this.hourTypeService.checkData();
        if (!correctness) {
            return { correctness, message: "HourTypes vide" };
        }

        correctness = await this.surveyKindService.checkData();
        if (!correctness) {
            return { correctness, message: "SurveyKinds vide" };
        }

        return { correctness, message: "" };
    }
}
