// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-row {
  width: 100%;
}

ion-input {
  --padding-bottom: 5px;
}

ion-toolbar {
  border-bottom: var(--ion-color-primary) solid thin;
}`, "",{"version":3,"sources":["webpack://./src/app/components/contract-search/contract-search.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,kDAAA;AACJ","sourcesContent":["ion-row {\r\n    width: 100%;\r\n}\r\n\r\nion-input {\r\n    --padding-bottom: 5px;\r\n}\r\n\r\nion-toolbar {\r\n    border-bottom: var(--ion-color-primary) solid thin;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
