import { Injectable } from "@angular/core";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { XVEGAID } from "../../../gyzmo-commons/http/header.constant";
import { HttpResponse } from "../../../gyzmo-commons/http/httpResponse";
import { DateProvider } from "../../../gyzmo-commons/interfaces/dateProvider";
import { CacheService } from "../../../gyzmo-commons/services/cache.service";
import { AttachmentDto } from "../../dto/attachment.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";
import { AttachmentKinds } from "../../interfaces/attachmentKinds";

@Injectable({
    providedIn: "root",
})
export class AttachmentWsDao extends WsDao<AttachmentDto> {
    static WS = "technical/attachments";
    static COMPANIES_WS = "companies";
    static THIRD_PARTIES_WS = "third-parties";

    constructor(private imgCacheService: CacheService,
                private dateProvider: DateProvider) {
        super();
    }

    public delete(serverConnection: ServerConnection, id: string, object: string, key: string) {
        return new Promise<void>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);
            tokens.set("object", object);
            tokens.set("key", key);

            serverConnection.delete(this.constructor.name, AttachmentWsDao.WS + "/:id/?object=:object&key=:key", tokens)
                .then(response => {
                    resolve();
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getById(serverConnection: ServerConnection, id: string, object?: string, key?: string): Promise<AttachmentDto> {
        if (!object || !key) {
            throw new Error("Args error : object and key have to be passed.");
        }

        return new Promise<AttachmentDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, AttachmentWsDao.WS + "/:id", tokens)
                .then(response => {
                    let attachment = AttachmentDto.fromBody(response.body, object, key);
                    resolve(attachment);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getByCompanyId(serverConnection: ServerConnection, companyId: string, attachmentKind?: AttachmentKinds): Promise<AttachmentDto[]> {
        return new Promise<AttachmentDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", companyId);

            serverConnection.get(this.constructor.name, AttachmentWsDao.COMPANIES_WS + "/:id/attachments?_limit=NOLIMIT", tokens)
                .then(response => {
                    this.getAttachmentsFromResponse(serverConnection, response, "company", companyId, attachmentKind)
                        .then(attachments => {
                            resolve(attachments);
                        });
                })
                .catch(reason => {
                    resolve([]);
                });
        });
    }

    public getByLinkedMovementId(serverConnection: ServerConnection, linkedMovementId: string, attachmentKind?: AttachmentKinds): Promise<AttachmentDto[]> {
        return new Promise<AttachmentDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("key", linkedMovementId);

            serverConnection.get(this.constructor.name, AttachmentWsDao.WS + "?object=linkedMovement&key=:key", tokens)
                .then(response => {
                    this.getAttachmentsFromResponse(serverConnection, response, "linkedMovement", linkedMovementId, attachmentKind)
                        .then(attachments => {
                            resolve(attachments);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getByThirdPartyId(serverConnection: ServerConnection, thirdPartyId: string, attachmentKind?: AttachmentKinds): Promise<AttachmentDto[]> {
        return new Promise<AttachmentDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", thirdPartyId);

            serverConnection.get(this.constructor.name, AttachmentWsDao.THIRD_PARTIES_WS + "/:id/attachments", tokens)
                .then(response => {
                    this.getAttachmentsFromResponse(serverConnection, response, "thirdParty", thirdPartyId, attachmentKind)
                        .then(attachments => {
                            resolve(attachments);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, attachmentDto: AttachmentDto): Promise<AttachmentDto> {
        return new Promise<AttachmentDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("object", attachmentDto.object);
            tokens.set("key", attachmentDto.key);

            if (!isNullOrEmpty(attachmentDto.id)) {
                tokens.set("id", attachmentDto.id);

                serverConnection.put(this.constructor.name, AttachmentWsDao.WS + "/:id/?object=:object&key=:key", tokens, attachmentDto.toBody())
                    .then(response => {
                        resolve(attachmentDto);
                    })
                    .catch(reason => {
                        reject(reason);
                    });
            } else {
                delete attachmentDto.id;

                serverConnection.post(this.constructor.name, AttachmentWsDao.WS + "?object=:object&key=:key", tokens, attachmentDto.toBody())
                    .then(response => {
                        attachmentDto.id = response.headers.get(XVEGAID.toLowerCase());
                        resolve(attachmentDto);
                    })
                    .catch(reason => {
                        reject(reason);
                    });
            }
        });
    }

    private async getAttachmentsFromResponse(serverConnection: ServerConnection,
                                             response: HttpResponse,
                                             object: string,
                                             key: string,
                                             attachmentKindFilter?: AttachmentKinds): Promise<AttachmentDto[]> {
        if (!response.body) {
            return [];
        }

        let promises = [];
        let attachments: AttachmentDto[] = [];

        (response.body as Array<any>).forEach(value => {
            if (!attachmentKindFilter || (attachmentKindFilter && value.attachmentKind.id == attachmentKindFilter)) {
                promises.push(new Promise<void>(async (resolve) => {
                    let isCached = await this.imgCacheService.isCached(object + "/" + key);

                    if (isCached) {
                        let content = await this.imgCacheService.getCached(object + "/" + key);

                        let attachment = AttachmentDto.fromBody(value, object, key);
                        attachment.file = content.value;

                        attachments.push(attachment);
                        resolve();
                    } else {
                        let attachmentDto = await this.getById(serverConnection, value.id, object, key);
                        attachments.push(attachmentDto);

                        if (!isNullOrEmpty(attachmentDto.file)) {
                            await this.imgCacheService.cache(object + "/" + key, attachmentDto.file, this.dateProvider.now().plus({ years: 10 }));
                            resolve();
                        } else if (attachmentDto.attachedDocuments && attachmentDto.attachedDocuments.length > 0) {
                            await this.imgCacheService.cache(object + "/" + key, attachmentDto.attachedDocuments[0].file, this.dateProvider.now().plus({ years: 10 }));
                            resolve();
                        } else {
                            resolve();
                        }
                    }
                }));
            }
        });

        await Promise.all(promises);
        return attachments;
    }
}
