import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class DatabaseEventService {
    static instance: DatabaseEventService = null;

    private databaseLogSubject: EventEmitter<void> = new EventEmitter();

    constructor() {
        DatabaseEventService.instance = this;
    }

    publishDatabaseLog() {
        this.databaseLogSubject.emit();
    }

    getDatabaseLogSubject(): EventEmitter<void> {
        return this.databaseLogSubject;
    }

    public addDom() {
        document.addEventListener("traceDB", this.askForDatabaseLog, true);
    }

    private askForDatabaseLog(e: CustomEvent) {
        e.preventDefault();
        e.stopImmediatePropagation();
        DatabaseEventService.instance.publishDatabaseLog();
    }
}
