import { Injectable } from "@angular/core";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/NotImplementedError";
import { DepartureMaterialDto, MaterialDto, ReturnCheckMaterialDto, ReturnMaterialDto } from "../../dto/material.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";
import { ConstraintWsDao } from "./constraint.ws.dao";
import { EquipmentWsDao } from "./equipment.ws.dao";
import { MovementWsDao } from "./movement.ws.dao";
import { ZoomWsDao } from "./zoom.ws.dao";

@Injectable({
    providedIn: "root",
})
export class MaterialsWsDao extends WsDao<MaterialDto> {
    constructor(private zoomWsDao: ZoomWsDao,
                private constraintWsDao: ConstraintWsDao,
                private equipmentWsDao: EquipmentWsDao,
                private movementWsDao: MovementWsDao) {
        super();
    }

    public getById(serverConnection: ServerConnection, id: string): Promise<MaterialDto> {
        throw new NotImplementedError();
    }

    public getDepartureMaterialLines(serverConnection: ServerConnection, invoiceId: string, groupingCode: string): Promise<DepartureMaterialDto[]> {
        return new Promise<DepartureMaterialDto[]>((resolve, reject) => {
            this.zoomWsDao.execute(serverConnection, "RECHERCHE_LIGNES_DEP", "_limit=NOLIMIT&pR570MVT=" + groupingCode)
                .then(materials => {
                    let result = [];
                    let hydratationPromises = [];

                    materials.forEach(material => {
                        let materialDto = DepartureMaterialDto.fromBody(material, invoiceId);

                        if (materialDto.equipment.id) {
                            hydratationPromises.push(this.equipmentWsDao.getById(serverConnection, materialDto.equipment.id)
                                .then(value => {
                                    materialDto.equipment = value;
                                }));
                        }

                        if (materialDto.movement.id) {
                            hydratationPromises.push(this.movementWsDao.getById(serverConnection, materialDto.movement.id)
                                .then(value => {
                                    materialDto.movement = value;
                                }));
                        }

                        result.push(materialDto);
                    });

                    Promise.all(hydratationPromises)
                        .then(value => {
                            resolve(result);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getReturnMaterialLines(serverConnection: ServerConnection, invoiceId: string, groupingCode: string): Promise<ReturnMaterialDto[]> {
        return new Promise<ReturnMaterialDto[]>((resolve, reject) => {
            this.zoomWsDao.execute(serverConnection, "RECHERCHE_LIGNES_RET", "_limit=NOLIMIT&pR570MVT=" + groupingCode)
                .then(materials => {
                    let result = [];
                    let hydratationPromises = [];

                    materials.forEach(material => {
                        let materialDto = ReturnMaterialDto.fromBody(material, invoiceId);

                        if (materialDto.equipment.id) {
                            hydratationPromises.push(this.equipmentWsDao.getById(serverConnection, materialDto.equipment.id)
                                .then(value => {
                                    materialDto.equipment = value;
                                }));
                        }

                        if (materialDto.movement.id) {
                            hydratationPromises.push(this.movementWsDao.getById(serverConnection, materialDto.movement.id)
                                .then(value => {
                                    materialDto.movement = value;
                                }));
                        }

                        result.push(materialDto);
                    });

                    Promise.all(hydratationPromises)
                        .then(value => {
                            resolve(result);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getReturnCheckMaterialLines(serverConnection: ServerConnection): Promise<ReturnCheckMaterialDto[]> {
        return new Promise<ReturnCheckMaterialDto[]>((resolve, reject) => {
            this.zoomWsDao.execute(serverConnection, "RECHERCHE_LIGNES_CHK", "_limit=NOLIMIT")
                .then(materials => {
                    let result = [];
                    let hydratationPromises = [];

                    materials.forEach(material => {
                        let materialDto = ReturnCheckMaterialDto.fromBody(material);

                        if (materialDto.equipment.id) {
                            hydratationPromises.push(this.equipmentWsDao.getById(serverConnection, materialDto.equipment.id)
                                .then(value => {
                                    materialDto.equipment = value;
                                }));
                        }

                        if (materialDto.movement.id) {
                            hydratationPromises.push(this.movementWsDao.getById(serverConnection, materialDto.movement.id)
                                .then(value => {
                                    materialDto.movement = value;
                                }));
                        }

                        result.push(materialDto);
                    });

                    Promise.all(hydratationPromises)
                        .then(value => {
                            resolve(result);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, materialDto: MaterialDto): Promise<MaterialDto> {
        return new Promise<MaterialDto>((resolve, reject) => {
            this.constraintWsDao.execute(serverConnection, "PUT_MATERIAL_LINE", "SORTIE", "_0", materialDto.toBody())
                .then(result => {
                    resolve(materialDto);
                });
        });
    }
}
