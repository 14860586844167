import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IHttpClient } from "../interfaces/IHttpClient";
import { LoggerService } from "../services/logs/logger.service";
import { HttpResponse } from "./httpResponse";

@Injectable({
    providedIn: "root",
})
export class AngularHttpClient implements IHttpClient {
    constructor(protected logger: LoggerService,
                protected httpClient: HttpClient) {
    }

    public async get(url: string, headers: {}): Promise<HttpResponse> {
        this.logger.info(this.constructor.name, "WebService get " + url);

        let response = await this.httpClient.get(url, {
            headers: new HttpHeaders(headers),
            observe: "response",
        }).toPromise();

        this.logger.debug(this.constructor.name, "WebService get response to " + url + " is " + JSON.stringify(response));

        return new HttpResponse(response.status, response.body, this.headersToMap(response.headers));
    }

    public async post(url: string, headers: {}, data: any): Promise<HttpResponse> {
        this.logger.info(this.constructor.name, "WebService post " + url);

        let response = await this.httpClient.post(url, data, {
            headers: new HttpHeaders(headers),
            observe: "response",
        }).toPromise();

        this.logger.debug(this.constructor.name, "WebService post response to " + url + " is " + JSON.stringify(response));

        return new HttpResponse(response.status, response.body, this.headersToMap(response.headers));
    }

    public async put(url: string, headers: {}, data: any): Promise<HttpResponse> {
        this.logger.info(this.constructor.name, "WebService put " + url);

        let response = await this.httpClient.put(url, data, {
            headers: new HttpHeaders(headers),
            observe: "response",
        }).toPromise();

        this.logger.debug(this.constructor.name, "WebService put response to " + url + " is " + JSON.stringify(response));

        return new HttpResponse(response.status, response.body, this.headersToMap(response.headers));
    }

    public async delete(url: string, headers: {}): Promise<HttpResponse> {
        this.logger.info(this.constructor.name, "WebService delete " + url);

        let response = await this.httpClient.delete(url, {
            headers: new HttpHeaders(headers),
            observe: "response",
        }).toPromise();

        this.logger.debug(this.constructor.name, "WebService delete response to " + url + " is " + JSON.stringify(response));

        return new HttpResponse(response.status, response.body, this.headersToMap(response.headers));
    }

    private headersToMap(headers: HttpHeaders) {
        let result = new Map<string, string>();

        headers.keys().forEach(key => {
            result.set(key, headers.get(key));
        });

        return result;
    }
}
