import { DateTime } from "luxon";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { DeliveryStates } from "../interfaces/deliveryStates";
import { LtcStatuses } from "../interfaces/ltcStatuses";
import { Delivery } from "../models/delivery.model";
import { BaseDto } from "./base/base.dto";
import { DeliveryLinkedMovementDto } from "./deliveryLinkedMovement.dto";
import { EquipmentDto } from "./equipment.dto";
import { LocationDto } from "./location.dto";
import { MovementDto } from "./movement.dto";
import { ThirdPartyDto } from "./thirdParty.dto";

export class DeliveryDto extends BaseDto<Delivery> {
    id: string = "";
    startDate: DateTime = null;
    plannedReturnDate: DateTime = null;
    returnDate: DateTime = null;
    status: LtcStatuses = LtcStatuses.DELIVERY;
    state: DeliveryStates = DeliveryStates.NOT_STARTED;

    // Fks
    driver: ThirdPartyDto;
    equipment: EquipmentDto;
    movement: MovementDto;
    linkedMovements: DeliveryLinkedMovementDto[] = [];
    startLocation: LocationDto;
    plannedReturnLocation: LocationDto;
    returnLocation: LocationDto;

    public isStillDownloading: boolean = false;

    constructor() {
        super();

        this.driver = new ThirdPartyDto();
        this.movement = new MovementDto();
        this.startLocation = new LocationDto();
        this.plannedReturnLocation = new LocationDto();
        this.returnLocation = new LocationDto();

        this.status = LtcStatuses.DELIVERY;

        this.equipment = new EquipmentDto();
    }

    static fromDeliveryZoom(value: any) {
        let deliveryDto = new DeliveryDto();

        deliveryDto.id = value.field9;
        deliveryDto.driver.id = value.field0;
        deliveryDto.driver.lastName = value.field1;
        deliveryDto.driver.firstName = value.field2;

        deliveryDto.startDate = DateHelper.tryFromFormat(value.field3, DATE_NODEJS_FORMAT);
        deliveryDto.plannedReturnDate = DateHelper.tryFromFormat(value.field4, DATE_NODEJS_FORMAT);

        deliveryDto.equipment.id = value.field5;
        deliveryDto.equipment.title = value.field6;
        deliveryDto.equipment.registrationNumber = value.field7;
        // value.field8;
        deliveryDto.movement.id = value.field9;

        return deliveryDto;
    }

    static fromModel(delivery: Delivery, isStillDownloading: boolean): DeliveryDto {
        let deliveryDto = new DeliveryDto();

        deliveryDto.id = delivery.id;
        deliveryDto.startDate = DateHelper.tryFromISO(delivery.startDate);
        deliveryDto.plannedReturnDate = DateHelper.tryFromISO(delivery.plannedReturnDate);
        deliveryDto.returnDate = DateHelper.tryFromISO(delivery.returnDate);
        deliveryDto.status = delivery.status;
        deliveryDto.state = delivery.state;

        deliveryDto.isStillDownloading = isStillDownloading;

        // Fks
        if (delivery.driver) {
            deliveryDto.driver = ThirdPartyDto.fromModel(delivery.driver);
        }

        if (delivery.movement) {
            deliveryDto.movement = MovementDto.fromModel(delivery.movement);
        }

        if (delivery.startLocation) {
            deliveryDto.startLocation = LocationDto.fromModel(delivery.startLocation);
        }
        if (delivery.plannedReturnLocation) {
            deliveryDto.plannedReturnLocation = LocationDto.fromModel(delivery.plannedReturnLocation);
        }
        if (delivery.returnLocation) {
            deliveryDto.returnLocation = LocationDto.fromModel(delivery.returnLocation);
        }

        if (delivery.equipment) {
            deliveryDto.equipment = EquipmentDto.fromModel(delivery.equipment);
        }

        deliveryDto.linkedMovements = [];
        if (delivery.linkedMovements) {
            delivery.linkedMovements.forEach(linkedMovement => {
                deliveryDto.linkedMovements.push(DeliveryLinkedMovementDto.fromModel(linkedMovement));
            });
        }

        return deliveryDto;
    }

    toBody() {
        let clone: DeliveryDto = this.clone();

        // Modifier l'object avant envoi
        // @ts-ignore
        clone.status = { id: this.status };
        delete clone.driver;
        delete clone.movement;
        delete clone.startLocation;
        delete clone.returnLocation;
        delete clone.plannedReturnLocation;

        return clone;
    }

    public toModel(): Delivery {
        let delivery = new Delivery();

        delivery.id = this.id;
        delivery.startDate = DateHelper.tryToISO(this.startDate);
        delivery.plannedReturnDate = DateHelper.tryToISO(this.plannedReturnDate);
        delivery.returnDate = DateHelper.tryToISO(this.returnDate);
        delivery.status = this.status;
        delivery.state = this.state;

        // Fks
        if (this.driver) {
            delivery.driver = this.driver.toModel();
        }
        if (this.movement) {
            delivery.movement = this.movement.toModel();
        }
        if (this.startLocation) {
            delivery.startLocation = this.startLocation.toModel();
        }
        if (this.plannedReturnLocation) {
            delivery.plannedReturnLocation = this.plannedReturnLocation.toModel();
        }
        if (this.returnLocation) {
            delivery.returnLocation = this.returnLocation.toModel();
        }

        if (this.equipment) {
            delivery.equipment = this.equipment.toModel();
        }

        delivery.linkedMovements = [];
        this.linkedMovements.forEach(linkedMovement => {
            delivery.linkedMovements.push(linkedMovement.toModel());
        });

        return delivery;
    }

    public isOnDay(activeDay: DateTime): boolean {
        return DateHelper.isSameDay(this.startDate, activeDay);
    }
}
