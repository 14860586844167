import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "../../helpers/pipes/pipes.module";
import { BrowserCameraModal } from "./browserCamera.modal";

@NgModule(
    {
        declarations: [
            BrowserCameraModal,
        ],
        imports: [
            CommonModule,
            IonicModule,
            HttpClientModule,
            TranslateModule,
            PipesModule,
        ],
        exports: [
            BrowserCameraModal,
        ],
        providers: [
        ],
    })

export class BrowserCameraModalModule {
}
