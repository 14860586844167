// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  cursor: pointer;
}
ion-card ion-card-header {
  display: flex;
  align-items: center;
}
ion-card ion-card-header ion-card-title {
  flex: auto;
  margin-top: 0;
  font-size: 16px;
  font-weight: bold;
}
ion-card ion-card-content {
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/equipment-info/equipment-info.component.scss"],"names":[],"mappings":"AACA;EACI,eAAA;AAAJ;AAEI;EACI,aAAA;EACA,mBAAA;AAAR;AAEQ;EACI,UAAA;EACA,aAAA;EACA,eAAA;EACA,iBAAA;AAAZ;AAII;EACI,aAAA;AAFR","sourcesContent":["\r\nion-card {\r\n    cursor: pointer;\r\n\r\n    ion-card-header {\r\n        display: flex;\r\n        align-items: center;\r\n\r\n        ion-card-title {\r\n            flex: auto;\r\n            margin-top: 0;\r\n            font-size: 16px;\r\n            font-weight: bold;\r\n        }\r\n    }\r\n\r\n    ion-card-content {\r\n        padding: 10px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
