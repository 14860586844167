import { Injectable } from "@angular/core";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/NotImplementedError";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class ConstraintWsDao extends WsDao<any> {
    static WS = "technical/scripts";

    public getById(serverConnection: ServerConnection, id: string): Promise<any> {
        throw new NotImplementedError();
    }

    public save(serverConnection: ServerConnection, constraint: any): Promise<any> {
        throw new NotImplementedError();
    }

    public execute(serverConnection: ServerConnection, zone: string, moment: string, variant: string, data: {}): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("zone", zone);
            tokens.set("variant", variant);
            tokens.set("moment", moment);

            serverConnection.post(this.constructor.name,
                ConstraintWsDao.WS + "?zone=:zone&moment=:moment&variant=:variant&lpServices=true",
                tokens,
                data,
                false)
                .then(value => {
                    resolve(value);
                });
        });
    }
}
