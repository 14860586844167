import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { environment } from "../../../environments/environment";
import { DeviceHelper } from "../../helpers/device.helper";
import { LOGGER_DATE_HOUR_FORMAT } from "../../interfaces/constants";
import { Logger } from "../../interfaces/logger";
import { LogLevel } from "../../interfaces/logLevel";
import { ConsoleLogger } from "./console.logger";
import { FileLogger } from "./file.logger";

@Injectable({
    providedIn: "root",
})
export class LoggerService {
    loggers: Logger[] = [];
    logLevel: LogLevel = LogLevel.DEBUG;

    constructor(private deviceHelper: DeviceHelper) {
    }

    public shareLogs() {
        this.loggers.forEach(logger => {
            void logger.shareLogs();
        });
    }

    public initialize() {
        this.logLevel = environment.logging.level;
        if (environment.logging.consoleLogger.active) {
            this.loggers.push(new ConsoleLogger());
        }
        if (environment.logging.fileLogger.active && this.deviceHelper.isRunningOnDevice()) {
            this.loggers.push(new FileLogger());
        }
    }

    public debug(className: string, message: string, ...params: any[]) {
        if (this.logLevel > LogLevel.DEBUG) {
            return;
        }

        this.loggers.forEach(logger => {
            logger.debug(this.getMessage(className, message), ...params);
        });
    }

    public info(className: string, message: string, ...params: any[]) {
        if (this.logLevel > LogLevel.INFO) {
            return;
        }

        this.loggers.forEach(logger => {
            logger.info(this.getMessage(className, message), ...params);
        });
    }

    public warn(className: string, message: string, ...params: any[]) {
        if (this.logLevel > LogLevel.WARN) {
            return;
        }

        this.loggers.forEach(logger => {
            logger.warn(this.getMessage(className, message), ...params);
        });
    }

    public error(className: string, message: string, ...params: any[]) {
        if (this.logLevel > LogLevel.ERROR) {
            return;
        }

        this.loggers.forEach(logger => {
            logger.error(this.getMessage(className, message), ...params);
        });
    }

    public table(table: any[]) {
        if (this.logLevel > LogLevel.INFO) {
            return;
        }

        this.loggers.forEach(logger => {
            logger.table(table);
        });
    }

    private getMessage(className: string, message: string) {
        return "[" + DateTime.now().toFormat(LOGGER_DATE_HOUR_FORMAT) + "] [" + className + "] " + message;
    }
}

