import { DateTime } from "luxon";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { NotImplementedError } from "../../gyzmo-commons/helpers/NotImplementedError";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { CheckStatus } from "../models/checkStatus";
import { BaseDto } from "./base/base.dto";
import { EquipmentDto } from "./equipment.dto";
import { InvoiceDto } from "./invoice.dto";
import { MovementDto } from "./movement.dto";

export enum MovementType {
    Start = "START",
    Return = "RETURN",
    Check = "CHECK",
}

export abstract class MaterialDto extends BaseDto<void> {
    public equipmentNature: string = "";
    public equipment: EquipmentDto = null;
    public movement: MovementDto = null;
    public invoice: InvoiceDto = null;
    public status: CheckStatus = null;
    public movementType: MovementType = MovementType.Start;

    // volatile
    public date: DateTime = null;

    constructor() {
        super();

        this.equipment = new EquipmentDto();
        this.movement = new MovementDto();
        this.invoice = new InvoiceDto();
    }

    static fromBodyBase(body: any, materialDto: MaterialDto, invoiceId: string, movementType: MovementType): MaterialDto {
        materialDto.movement.id = body.field0;
        materialDto.movement.startDate = DateHelper.tryFromISO(body.field1);
        materialDto.movement.returnDate = DateHelper.tryFromISO(body.field2);

        materialDto.equipmentNature = body.field7;
        materialDto.movementType = movementType;

        if (materialDto.equipmentNature == "MATID") {
            materialDto.movement.startMileage = body.field3;
            materialDto.movement.returnMileage = body.field4;
            materialDto.movement.startMileage2 = body.field5;
            materialDto.movement.returnMileage2 = body.field6;

            materialDto.equipment.id = body.field9;
            materialDto.equipment.title = body.field8;
        }

        materialDto.equipment.model.id = body.field16;
        materialDto.equipment.model.wording = body.field8;

        if (body.field17) {
            // Model "Hors catalogue"
            materialDto.equipment.title = body.field17;
            materialDto.equipment.model.wording = body.field17;
        }

        materialDto.invoice.id = invoiceId;

        return materialDto;
    }

    public static sorter(a: any, b: any) {
        if (a.remainingQuantity == a.returnQuantity || a.remainingQuantity == 0) {
            return 1;
        }
        if (b.remainingQuantity == b.returnQuantity || b.remainingQuantity == 0) {
            return -1;
        }

        return a.equipment.model.wording.localeCompare(b.equipment.model.wording);
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.date = DateHelper.tryToFormat(this.date, DATE_NODEJS_FORMAT);

        if (this.equipmentNature == "MATQT" || this.equipmentNature == "CONSO") {
            delete clone.equipment;
        }

        return clone;
    }

    public toModel(): void {
        throw new NotImplementedError();
    }
}

export class DepartureMaterialDto extends MaterialDto {
    public totalOrderedQuantity: number = 0;
    public remainingQuantityToPrepare: number = 0;
    public preparedQuantity: number = 0;

    // Volatile
    public savedRemainingQuantityToPrepare: number = 0;

    public static fromBody(body: any, invoiceId: string): MaterialDto {
        let materialDto = new DepartureMaterialDto();
        MaterialDto.fromBodyBase(body, materialDto, invoiceId, MovementType.Start);

        materialDto.totalOrderedQuantity = Number(body.field14);
        materialDto.remainingQuantityToPrepare = Number(body.field15);
        materialDto.savedRemainingQuantityToPrepare = materialDto.remainingQuantityToPrepare;
        materialDto.preparedQuantity = 0;

        return materialDto;
    }

    public isFullyPrepared() {
        let lineValid = true;

        if (this.equipmentNature == "MATID") {
            if (this.status == null) {
                lineValid = false;
            }
            if (this.equipment.personalModel.counterKind.id != "0"
                && this.movement.startMileage <= 0) {
                lineValid = false;
            }
            if (this.equipment.personalModel.counterKind2.id != "0"
                && this.movement.startMileage2 <= 0) {
                lineValid = false;
            }
        } else {
            if (this.status == null) {
                lineValid = false;
            }
            if (this.remainingQuantityToPrepare > 0) {
                lineValid = false;
            }
        }

        return lineValid;
    }
}

export class ReturnMaterialDto extends MaterialDto {
    public totalOrderedQuantity: number = 0;
    public remainingQuantityOnSite: number = 0;
    public returnQuantity: number = 0;

    // Volatile
    public savedRemainingQuantityOnSite: number = 0;

    public static fromBody(body: any, invoiceId: string): MaterialDto {
        let materialDto = new ReturnMaterialDto();
        MaterialDto.fromBodyBase(body, materialDto, invoiceId, MovementType.Return);

        materialDto.totalOrderedQuantity = Number(body.field14);
        materialDto.remainingQuantityOnSite = Number(body.field15);
        materialDto.savedRemainingQuantityOnSite = materialDto.remainingQuantityOnSite;
        materialDto.returnQuantity = 0;

        return materialDto;
    }

    public isFullyReturned() {
        let lineValid = true;

        if (this.equipmentNature == "MATID") {
            if (this.status == null) {
                lineValid = false;
            }
            if (this.equipment.personalModel.counterKind.id != "0"
                && this.movement.returnMileage <= this.movement.startMileage) {
                lineValid = false;
            }
            if (this.equipment.personalModel.counterKind2.id != "0"
                && this.movement.returnMileage2 <= this.movement.startMileage2) {
                lineValid = false;
            }
        } else {
            if (this.status == null) {
                lineValid = false;
            }
            if (this.remainingQuantityOnSite > 0) {
                lineValid = false;
            }
        }

        return lineValid;
    }
}

export class ReturnCheckMaterialDto extends MaterialDto {
    public totalReturnedQuantity: number = 0;
    public remainingReturnedQuantity: number = 0;
    public checkedQuantity: number = 0;

    // Volatile
    public savedRemainingReturnedQuantity: number = 0;

    constructor() {
        super();
    }

    public static fromBody(body: any): ReturnCheckMaterialDto {
        let materialDto = new ReturnCheckMaterialDto();
        /*
         0 F570KY
         1 ARR
         2 DEP
         3 POSIT
         4 AGENCE
         5 MODELE
         6 SOLDE
         7 R570MVT
         8 K570090UNI
         9 R570MVTLIG
         10 K570400EVT
         11 F570NUMBONARR
         12 R570570ACCMVTLIG
         13 F570VERFORCEE
         14 K570060VER
         15 F060LIB
         16 K060TD3NAT
         17 F901MSG
         18 F030LIB
         19 F090KY
         20 F090LIB
         */

        materialDto.movement.id = body.field0;
        materialDto.movementType = MovementType.Check;

        materialDto.totalReturnedQuantity = Number(body.field1);
        materialDto.remainingReturnedQuantity = Number(body.field6);
        materialDto.savedRemainingReturnedQuantity = materialDto.remainingReturnedQuantity;
        materialDto.checkedQuantity = 0;

        materialDto.equipmentNature = body.field16;

        if (materialDto.equipmentNature == "MATID") {
            materialDto.equipment.id = body.field19;
            materialDto.equipment.title = body.field20;
        }

        materialDto.equipment.model.id = body.field5;
        materialDto.equipment.model.wording = body.field15;

        materialDto.invoice.id = body.field10;

        if (body.field21) {
            // Model "Hors catalogue"
            materialDto.equipment.title = body.field21;
            materialDto.equipment.model.wording = body.field21;
        }

        return materialDto;
    }

    public isFullyChecked() {
        let lineValid = true;

        if (this.equipmentNature == "MATID") {
            if (this.status == null) {
                lineValid = false;
            }
        } else {
            if (this.status == null) {
                lineValid = false;
            }
            if (this.remainingReturnedQuantity > 0) {
                lineValid = false;
            }
        }

        return lineValid;
    }
}
