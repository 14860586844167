import { Injectable } from "@angular/core";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/NotImplementedError";
import { InvoiceLineDto } from "../../dto/invoiceLine.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";
import { InvoiceWsDao } from "./invoice.ws.dao";

@Injectable({
    providedIn: "root",
})
export class InvoiceLineWsDao extends WsDao<InvoiceLineDto> {
    static WS = "invoices";

    public getById(serverConnection: ServerConnection, id: string): Promise<InvoiceLineDto> {
        throw new NotImplementedError();
    }

    public save(serverConnection: ServerConnection, invoiceLineDto: InvoiceLineDto): Promise<InvoiceLineDto> {
        return new Promise<InvoiceLineDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", invoiceLineDto.invoiceId);
            tokens.set("idLine", invoiceLineDto.id);

            serverConnection.put(this.constructor.name, InvoiceWsDao.WS + "/:id/lines/:idLine", tokens, invoiceLineDto.toBody())
                .then(response => {
                    resolve(invoiceLineDto);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
