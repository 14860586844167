import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { EquipmentService } from "../../../core/services/equipment.service";
import { PipesModule } from "../../../gyzmo-commons/helpers/pipes/pipes.module";
import { EquipmentInfoComponentModule } from "../../components/equipment-info/equipment-info.module";
import { EquipmentSearchModal } from "./equipment-search.modal";

@NgModule(
    {
        declarations: [
            EquipmentSearchModal,
        ],
        imports: [
            CommonModule,
            FormsModule,
            IonicModule,
            PipesModule,
            TranslateModule,
            EquipmentInfoComponentModule,
        ],
        providers: [
            EquipmentService,
        ],
    })

export class EquipmentSearchModalModule {
}
