import { Injectable } from "@angular/core";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/NotImplementedError";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { ConstructionSiteDto } from "../../dto/constructionSite.dto";
import { ReturnSearchResultDto } from "../../dto/returnSearchResult.dto";
import { ThirdPartyDto } from "../../dto/thirdParty.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";
import { ThirdPartyWsDao } from "./thirdParty.ws.dao";
import { ZoomWsDao } from "./zoom.ws.dao";

@Injectable({
    providedIn: "root",
})
export class ConstructionSiteWsDao extends WsDao<ConstructionSiteDto> {
    static WS = "construction-sites";

    constructor(private thirdPartyWsDao: ThirdPartyWsDao,
                private zoomWsDao: ZoomWsDao) {
        super();
    }

    public getById(serverConnection: ServerConnection, id: string): Promise<ConstructionSiteDto> {
        return new Promise<ConstructionSiteDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, ConstructionSiteWsDao.WS + "/:id", tokens)
                .then(response => {
                    let constructionSite = ConstructionSiteDto.fromBody(response.body);
                    let promises = [];

                    promises.push(this.getWorksManager(serverConnection, constructionSite.id)
                        .then(worksManager => {
                            if (worksManager) {
                                constructionSite.worksManager = worksManager;
                            }
                        }));

                    promises.push(this.getConstructionSiteManager(serverConnection, constructionSite.id)
                        .then(constructionSiteManager => {
                            if (constructionSiteManager) {
                                constructionSite.constructionSiteManager = constructionSiteManager;
                            }
                        }));

                    Promise.all(promises)
                        .then(ignored => {
                            resolve(constructionSite);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public searchByDeparture(serverConnection: ServerConnection, searchTerm: string): Promise<ReturnSearchResultDto[]> {
        return new Promise<ReturnSearchResultDto[]>((resolve, reject) => {
            this.zoomWsDao.execute(serverConnection, "RECHERCHE_DEPARTS",
                "_limit=100"
                + "&pSearchTerm=" + searchTerm)
                .then(zoomResults => {
                    let results = [];
                    let hydratationPromises = [];

                    zoomResults.forEach(zoomResult => {
                        let returnCheckStatusDto = ReturnSearchResultDto.fromBody(zoomResult);
                        results.push(returnCheckStatusDto);
                    });

                    Promise.all(hydratationPromises)
                        .then(value => {
                            resolve(results);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public searchByReturn(serverConnection: ServerConnection, searchTerm: string): Promise<ReturnSearchResultDto[]> {
        return new Promise<ReturnSearchResultDto[]>((resolve, reject) => {
            this.zoomWsDao.execute(serverConnection, "RECHERCHE_RETOURS",
                "_limit=100"
                + "&pSearchTerm=" + searchTerm)
                .then(zoomResults => {
                    let results = [];
                    let hydratationPromises = [];

                    zoomResults.forEach(zoomResult => {
                        let returnCheckStatusDto = ReturnSearchResultDto.fromBody(zoomResult);
                        results.push(returnCheckStatusDto);
                    });

                    Promise.all(hydratationPromises)
                        .then(value => {
                            resolve(results);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, company: ConstructionSiteDto): Promise<ConstructionSiteDto> {
        throw new NotImplementedError();
    }

    private getWorksManager(serverConnection: ServerConnection, id: string): Promise<ThirdPartyDto> {
        return new Promise<ThirdPartyDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, ConstructionSiteWsDao.WS + "/:id/relations", tokens)
                .then(response => {
                    if (response.body) {
                        let responsibleId = "";
                        let relations: Array<any> = (response.body as Array<any>);

                        relations.forEach(relation => {
                            if (relation.link && relation.link.id == "COND") {
                                responsibleId = relation.son.id;
                            }
                        });

                        if (!isNullOrEmpty(responsibleId)) {
                            this.thirdPartyWsDao.getById(serverConnection, responsibleId)
                                .then(value => {
                                    resolve(value);
                                });
                        } else {
                            resolve(null);
                        }
                    } else {
                        resolve(null);
                    }
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    private getConstructionSiteManager(serverConnection: ServerConnection, id: string): Promise<ThirdPartyDto> {
        return new Promise<ThirdPartyDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, ConstructionSiteWsDao.WS + "/:id/relations", tokens)
                .then(response => {
                    if (response.body) {
                        let responsibleId = "";
                        let relations: Array<any> = (response.body as Array<any>);

                        relations.forEach(relation => {
                            if (relation.link && relation.link.id == "CHEF") {
                                responsibleId = relation.son.id;
                            }
                        });

                        if (!isNullOrEmpty(responsibleId)) {
                            this.thirdPartyWsDao.getById(serverConnection, responsibleId)
                                .then(value => {
                                    resolve(value);
                                });
                        } else {
                            resolve(null);
                        }
                    } else {
                        resolve(null);
                    }
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
