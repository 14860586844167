import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { DATE_NODEJS_FORMAT } from "../../../gyzmo-commons/interfaces/constants";
import { InvoiceDto } from "../../dto/invoice.dto";
import { LocationDto } from "../../dto/location.dto";
import { MovementDto } from "../../dto/movement.dto";
import { OrderDto } from "../../dto/order.dto";
import { OrderLinkedMovementDto } from "../../dto/orderLinkedMovement.dto";
import { ThirdPartyDto } from "../../dto/thirdParty.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";
import { AttachmentWsDao } from "./attachment.ws.dao";
import { EquipmentWsDao } from "./equipment.ws.dao";
import { InvoiceWsDao } from "./invoice.ws.dao";
import { LocationWsDao } from "./location.ws.dao";
import { ModelWsDao } from "./model.ws.dao";
import { MovementWsDao } from "./movement.ws.dao";
import { ThirdPartyWsDao } from "./thirdParty.ws.dao";
import { ZoomWsDao } from "./zoom.ws.dao";

@Injectable({
    providedIn: "root",
})
export class OrderWsDao extends WsDao<OrderDto> {
    static WS = "long-term-contracts";

    constructor(private thirdPartyWsDao: ThirdPartyWsDao,
                private equipmentWsDao: EquipmentWsDao,
                private modelWsDao: ModelWsDao,
                private locationWsDao: LocationWsDao,
                private attachmentWsDao: AttachmentWsDao,
                private movementWsDao: MovementWsDao,
                private invoiceWsDao: InvoiceWsDao,
                private zoomWsDao: ZoomWsDao) {
        super();
    }

    public getById(serverConnection: ServerConnection, id: string): Promise<OrderDto> {
        return new Promise<OrderDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, OrderWsDao.WS + "/:id", tokens)
                .then(response => {
                    let ltcDto = OrderDto.fromBody(response.body, true);
                    let promises = [];

                    if (ltcDto.movement && ltcDto.movement.id) {
                        promises.push(this.movementWsDao.getById(serverConnection, ltcDto.movement.id)
                            .then((value: MovementDto) => {
                                ltcDto.movement = value;
                            }));
                    }

                    if (ltcDto.invoice && ltcDto.invoice.id) {
                        promises.push(this.invoiceWsDao.getById(serverConnection, ltcDto.invoice.id)
                            .then((value: InvoiceDto) => {
                                ltcDto.invoice = value;
                            }));
                    }

                    if (ltcDto.customer && ltcDto.customer.id) {
                        promises.push(this.thirdPartyWsDao.getById(serverConnection, ltcDto.customer.id)
                            .then((value: ThirdPartyDto) => {
                                ltcDto.customer = value;
                            }));
                    }

                    if (ltcDto.driver && ltcDto.driver.id) {
                        promises.push(this.thirdPartyWsDao.getById(serverConnection, ltcDto.driver.id)
                            .then((value: ThirdPartyDto) => {
                                ltcDto.driver = value;
                            }));
                    }

                    if (ltcDto.merchant && ltcDto.merchant.id) {
                        promises.push(this.thirdPartyWsDao.getById(serverConnection, ltcDto.merchant.id)
                            .then((value: ThirdPartyDto) => {
                                ltcDto.merchant = value;
                            }));
                    }

                    if (ltcDto.startLocation && ltcDto.startLocation.id) {
                        promises.push(this.locationWsDao.getById(serverConnection, ltcDto.startLocation.id)
                            .then((value: LocationDto) => {
                                ltcDto.startLocation = value;
                            }));
                    }

                    if (ltcDto.plannedReturnLocation && ltcDto.plannedReturnLocation.id) {
                        promises.push(this.locationWsDao.getById(serverConnection, ltcDto.plannedReturnLocation.id)
                            .then((value: LocationDto) => {
                                ltcDto.plannedReturnLocation = value;
                            }));
                    }

                    if (ltcDto.returnLocation && ltcDto.returnLocation.id) {
                        promises.push(this.locationWsDao.getById(serverConnection, ltcDto.returnLocation.id)
                            .then((value: LocationDto) => {
                                ltcDto.returnLocation = value;
                            }));
                    }

                    Promise.all(promises)
                        .then(ignored => {
                            resolve(ltcDto);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, orderDto: OrderDto): Promise<OrderDto> {
        return new Promise<OrderDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", orderDto.id);

            serverConnection.put(this.constructor.name, OrderWsDao.WS + "/:id", tokens, orderDto.toBody())
                .then(response => {
                    resolve(orderDto);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getLinkedMovements(serverConnection: ServerConnection, ltcDto: OrderDto): Promise<OrderLinkedMovementDto[]> {
        return new Promise<OrderLinkedMovementDto[]>((resolve, reject) => {
            let params = "_limit=NOLIMIT&pF470KY=" + ltcDto.id;

            this.zoomWsDao.execute(serverConnection, "LISTE_CONTRAT_A_PREP", params)
                .then(response => {
                    let promises = [];
                    let result: OrderLinkedMovementDto[] = [];

                    response.forEach(async (value: any) => {
                        let orderLinkedMovementDto = OrderLinkedMovementDto.fromOrderZoom(value);

                        if (orderLinkedMovementDto.model.id) {
                            promises.push(this.modelWsDao.getById(serverConnection, orderLinkedMovementDto.model.id)
                                .then(model => {
                                    orderLinkedMovementDto.model = model;
                                }));
                        }

                        if (orderLinkedMovementDto.equipment.id) {
                            promises.push(this.equipmentWsDao.getById(serverConnection, orderLinkedMovementDto.equipment.id)
                                .then(equipment => {
                                    orderLinkedMovementDto.equipment = equipment;
                                }));
                        }

                        result.push(orderLinkedMovementDto);
                    });

                    Promise.all(promises)
                        .then(ignored => {
                            resolve(result);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getList(serverConnection: ServerConnection, startDate: DateTime, endDate: DateTime, hydrate = false): Promise<OrderDto[]> {
        return new Promise<OrderDto[]>((resolve, reject) => {
            let params = "_limit=NOLIMIT";
            if (startDate) {
                params += "&pF570DTDEPDEB=" + startDate.toFormat(DATE_NODEJS_FORMAT);
            } else {
                params += "&pF570DTDEPDEB=";
            }

            if (endDate) {
                params += "&pF570DTDEPFIN=" + endDate.toFormat(DATE_NODEJS_FORMAT);
            } else {
                params += "&pF570DTDEPFIN=";
            }

            this.zoomWsDao.execute(serverConnection, "LISTE_CONTRAT_A_PREP", params)
                .then(response => {
                    let promises = [];
                    let result: OrderDto[] = [];
                    response.forEach(async (value: any) => {
                        let orderDto = new OrderDto();
                        orderDto.id = value.field0;

                        let filter = result.filter(element => element.id === orderDto.id);
                        if (filter.length > 0) {
                            orderDto = filter[0];
                        } else {
                            orderDto = OrderDto.fromOrderZoom(value);
                            result.push(orderDto);
                        }

                        let orderLinkedMovementDto = OrderLinkedMovementDto.fromOrderZoom(value);

                        if (hydrate) {
                            if (orderLinkedMovementDto.model.id) {
                                promises.push(this.modelWsDao.getById(serverConnection, orderLinkedMovementDto.model.id)
                                    .then(equipment => {
                                        orderLinkedMovementDto.model = equipment;
                                    }));
                            }

                            if (orderLinkedMovementDto.equipment.id) {
                                promises.push(this.equipmentWsDao.getById(serverConnection, orderLinkedMovementDto.equipment.id)
                                    .then(equipment => {
                                        orderLinkedMovementDto.equipment = equipment;
                                    }));
                            }

                            promises.push(this.attachmentWsDao.getByLinkedMovementId(serverConnection, orderLinkedMovementDto.id)
                                .then(attachments => {
                                    orderLinkedMovementDto.attachments.push(...attachments);
                                }));
                        }
                        orderDto.linkedMovements.push(orderLinkedMovementDto);
                    });

                    Promise.all(promises)
                        .then(ignored => {
                            resolve(result);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
