import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { saveAs } from "file-saver";
import { isNullOrEmpty } from "../helpers/null.helper";

@Injectable()
export class ExtendedTranslateService {
    constructor(private translateService: TranslateService) {
    }

    public exportTranslations() {
        /*
         INSERT INTO `dictionary` (`DICTIONARY_ID_FORMAT`, `TYPE_ID`, `HIDDEN`, `COMMENTS`) VALUES ('IC_check_out', 30, 0, '');


         INSERT INTO `translate` (`LANGUAGE_ID`, `DICTIONARY_ID`, `DICTIONARY_ID_FORMAT`, `TRANSLATION_TEXT`, `ORIGINE`)
         VALUES (0, 'IC_check_out', 'IC_check_out', 'Check out', 'IMPORT');

         INSERT INTO `translate` (`LANGUAGE_ID`, `DICTIONARY_ID`, `DICTIONARY_ID_FORMAT`, `TRANSLATION_TEXT`, `ORIGINE`)
         VALUES (1, 'IC_check_out', 'IC_check_out', 'Sortie', 'IMPORT');
         */

        let allKeys = [];
        let query_dictionary = "";
        let query_fr = "-- FR\n";
        let query_en = "-- EN\n";

        for (let key in this.translateService.translations.fr) {
            if (isNullOrEmpty(this.translateService.translations.en[key])) {
                console.error(key + " non trouvée dans la langue cible.");
            }

            let formatedKey = this.toKey(this.translateService.translations.en[key]);

            if (allKeys.indexOf(formatedKey) < 0) {
                allKeys.push(formatedKey);
            } else {
                console.error("Doublon : " + formatedKey);
            }

            query_dictionary += "INSERT INTO `dictionary` (`DICTIONARY_ID_FORMAT`, `TYPE_ID`, `HIDDEN`, `COMMENTS`) VALUES ('" + formatedKey + "', 30, 0, '');\n";

            query_en += "INSERT INTO `translate` (`LANGUAGE_ID`, `DICTIONARY_ID`, `DICTIONARY_ID_FORMAT`, `TRANSLATION_TEXT`, `ORIGINE`)\n"
                        + "VALUES (0, '" + formatedKey + "', '" + formatedKey + "', '" + this.escapeQuotes(this.translateService.translations.en[key]) + "', 'IMPORT');\n";

            query_fr += "INSERT INTO `translate` (`LANGUAGE_ID`, `DICTIONARY_ID`, `DICTIONARY_ID_FORMAT`, `TRANSLATION_TEXT`, `ORIGINE`)\n"
                        + "VALUES (1, '" + formatedKey + "', '" + formatedKey + "', '" + this.escapeQuotes(this.translateService.translations.fr[key]) + "', 'IMPORT');\n";
        }

        this.downloadSql("BEGIN;\n\n" + query_dictionary + "\n" + query_en + "\n" + query_fr + "\n\n" + "COMMIT;\n", "translations.sql");
    }

    private toKey(key: string) {
        let formatedKey = "IMCP_"
                          + key.replaceAll(" ", "_")
                              .replaceAll(",", "_")
                              .replaceAll("°", "_")
                              .replaceAll("\"", "_")
                              .replaceAll("'", "_");

        return formatedKey.replaceAll("__", "_");
    }

    // https://gist.github.com/getify/3667624
    private escapeQuotes(str): string {
        return str.replace(/[\\']/g, "''");
    }

    private downloadSql(content: string, filename: string) {
        let blob = new Blob([content], { type: "text/plain;charset=utf-8" });
        saveAs(blob, filename);
    }
}
