import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ConstructionSiteWsDao } from "../../../core/dao/ws/constructionSite.ws.dao";
import { ReturnSearchResultDto } from "../../../core/dto/returnSearchResult.dto";
import { ServersConnectionsProvider } from "../../../core/providers/serversConnections.provider";
import { DATE_FORMAT } from "../../../gyzmo-commons/interfaces/constants";
import { DateProvider } from "../../../gyzmo-commons/interfaces/dateProvider";

@Component({
    selector: "contract-search",
    templateUrl: "contract-search.component.html",
    styleUrls: ["contract-search.component.scss"],
})
export class ContractSearchComponent {
    @Input() type: string = "";
    @Output() contractSelected: EventEmitter<ReturnSearchResultDto> = new EventEmitter();
    public returnSearchResults: ReturnSearchResultDto[] = null;
    public searchTerm: string = "";
    public showHelp: boolean = false;
    public searching: boolean = false;

    constructor(private modalController: ModalController,
                private serversConnectionsProvider: ServersConnectionsProvider,
                private constructionSiteWsDao: ConstructionSiteWsDao,
                private dateProvider: DateProvider) {
    }

    public dismiss(data?: any) {
        this.modalController.dismiss(data);
    }

    public setCurrentDate() {
        this.searchTerm = this.dateProvider.now().toFormat(DATE_FORMAT);
    }

    public search() {
        this.searching = true;
        switch (this.type) {
            case "departure":
                this.constructionSiteWsDao.searchByDeparture(this.serversConnectionsProvider.getPrimaryServerConnection(), this.searchTerm)
                    .then((returnSearchResults: ReturnSearchResultDto[]) => {
                        this.returnSearchResults = returnSearchResults;
                        this.searching = false;
                    });
                break;
            case "return":
                this.constructionSiteWsDao.searchByReturn(this.serversConnectionsProvider.getPrimaryServerConnection(), this.searchTerm)
                    .then((returnSearchResults: ReturnSearchResultDto[]) => {
                        this.returnSearchResults = returnSearchResults;
                        this.searching = false;
                    });
                break;
        }
    }

    startWithContract(returnSearchResultDto: ReturnSearchResultDto) {
        if (this.contractSelected) {
            this.contractSelected.emit(returnSearchResultDto);
        }
    }
}
