import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { IonContent, IonRow, ModalController, Platform } from "@ionic/angular";
import { Subscription } from "rxjs";
import { Angular2SignaturePadComponent, Options } from "../../components/angular2-signaturepad/angular2-signaturepad.component";
import { DeviceHelper } from "../../helpers/device.helper";
import { NavControllerExtended } from "../../helpers/navControllerExtended";

@Component({
    templateUrl: "draw.modal.html",
    styleUrls: ["draw.modal.scss"],
})
export class DrawModal {
    @ViewChild("content", { static: true }) content: IonContent;
    @ViewChild("containerRow", { static: true }) containerRow: IonRow;
    @ViewChild("container", { static: true }) container: ElementRef;
    @ViewChild("signaturePad", { static: true }) signaturePad: Angular2SignaturePadComponent;
    @ViewChild("backgroundCanvas", { static: true }) backgroundCanvas: ElementRef;

    @Input() imageData: string = "";
    @Input() exportQuality: number = 80;

    blueColor = "#2196F3";
    greenColor = "#4CAF50";
    redColor = "#FF2617";
    yellowColor = "#F0FF17";

    smallSize = 2;
    mediumSize = 4;
    largeSize = 8;

    drawSize: number = 0;
    drawColor: string = "";

    subscription: Subscription;

    constructor(private navCtrl: NavControllerExtended,
                private modalController: ModalController,
                private platform: Platform,
                private deviceHelper: DeviceHelper) {
        if (this.navCtrl.params.hasParam("imageData")) {
            this.imageData = this.navCtrl.params.getParam("imageData") as string;
        }
        if (this.navCtrl.params.hasParam("exportQuality")) {
            this.exportQuality = this.navCtrl.params.getParam("exportQuality") as number;
        }
    }

    public ionViewDidEnter() {
        if (this.deviceHelper.isRunningOnDevice()) {
            this.subscription = this.platform.backButton.subscribeWithPriority(0, () => {
                this.dismiss();
            });
        }

        if (this.imageData.length > 0) {
            let image = new Image();
            image.src = this.imageData;
            image.onload = () => {
                this.setContainerSize(image);

                this.drawBackground(image);

                setTimeout(() => {
                    this.setColor(this.blueColor);
                    this.setDrawSize(this.mediumSize);

                    this.signaturePad.set(Options.canvasWidth, image.naturalWidth);
                    this.signaturePad.set(Options.canvasHeight, image.naturalHeight);
                    this.signaturePad.set(Options.backgroundColor, "rgba(255,255,255,0)");
                }, 100);
            };
        }
    }

    public ionViewWillLeave() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public clear() {
        this.signaturePad.clear();
    }

    public save() {
        let base64 = this.signaturePad.toDataURL("image/png", 1);

        // Merger le dessin avec le background
        let image = new Image();
        image.src = base64;
        image.onload = () => {
            let canvas = this.backgroundCanvas.nativeElement as HTMLCanvasElement;
            let ctx: CanvasRenderingContext2D = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);
            base64 = canvas.toDataURL("image/jpeg", this.exportQuality > 1 ? this.exportQuality / 100 : this.exportQuality);

            this.modalController.dismiss({ base64: base64 });
        };
    }

    public dismiss() {
        this.modalController.dismiss();
    }

    public setColor(color: string) {
        this.drawColor = color;
        this.signaturePad.set(Options.penColor, color);
    }

    public setDrawSize(size: number) {
        this.drawSize = size;
        this.signaturePad.set(Options.minWidth, size);
        this.signaturePad.set(Options.dotSize, size);
    }

    private setContainerSize(image: HTMLImageElement) {
        let contentWidth = this.containerRow["el"].clientWidth;
        let contentHeight = this.containerRow["el"].clientHeight;

        this.container.nativeElement.style.maxWidth = contentWidth + "px";
        this.container.nativeElement.style.maxHeight = contentHeight + "px";

        if (image.naturalWidth > contentWidth && image.naturalHeight > contentHeight) {
            if (image.naturalHeight > image.naturalWidth) {
                // Portrait
                let width: number = contentHeight / (image.naturalHeight / image.naturalWidth);
                this.container.nativeElement.style.width = width + "px";
                this.container.nativeElement.style.height = contentHeight + "px";
            } else {
                // Landscape
                let height: number = contentWidth / (image.naturalWidth / image.naturalHeight);
                this.container.nativeElement.style.width = contentWidth + "px";
                this.container.nativeElement.style.height = height + "px";
            }
        } else {
            this.container.nativeElement.style.width = image.naturalWidth + "px";
            this.container.nativeElement.style.height = image.naturalHeight + "px";
        }
    }

    private drawBackground(image: HTMLImageElement) {
        let canvas = this.backgroundCanvas.nativeElement;
        let ctx: CanvasRenderingContext2D = canvas.getContext("2d");
        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;
        ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);
    }
}
