import { Injectable } from "@angular/core";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/NotImplementedError";
import { CheckStatusDto } from "../../dto/checkStatus.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class CheckStatusWsDao extends WsDao<CheckStatusDto> {
    public getById(serverConnection: ServerConnection, id: string): Promise<CheckStatusDto> {
        throw new NotImplementedError();
    }

    public save(serverConnection: ServerConnection, inspection: CheckStatusDto): Promise<CheckStatusDto> {
        throw new NotImplementedError();
    }

    public getList(serverConnection: ServerConnection): Promise<CheckStatusDto[]> {
        return new Promise<CheckStatusDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("ft", "FTE4POSLIE");

            serverConnection.get(this.constructor.name, "technical/ft/:ft/execute?_limit=NOLIMIT", tokens)
                .then(response => {
                    let results = [];
                    let ftElements: Array<any> = (response.body as Array<any>);

                    ftElements.forEach(ftElement => {
                        results.push(CheckStatusDto.fromBody(ftElement));
                    });

                    resolve(results);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
