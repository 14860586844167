import { Directory, Encoding, Filesystem, GetUriOptions } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";
import { MODULE } from "../../../app/app.constants";
import { Logger } from "../../interfaces/logger";

export class FileLogger implements Logger {
    path = "logs/debug.log";
    directory = Directory.External;

    private buffer: string[] = [];

    constructor() {
        void this.createFileIfNeeded();

        setInterval(() => {
            let bufferCopy = [...this.buffer];
            this.buffer = [];

            void this.writeLogs(bufferCopy);
        }, 5000);
    }

    debug(...args: string[]): void {
        this.buffer.push(this.logToString("DEBUG", args));
    }

    info(...args: string[]): void {
        this.buffer.push(this.logToString("INFO", args));
    }

    warn(...args: string[]): void {
        this.buffer.push(this.logToString("WARN", args));
    }

    error(...args: string[]): void {
        this.buffer.push(this.logToString("ERROR", args));
    }

    public async shareLogs(): Promise<void> {
        let uriResult = await Filesystem.getUri({ path: this.path, directory: this.directory });

        await Share.share({
            text: "App Logs Attached",
            title: "[" + MODULE + "] Logs",
            files: [uriResult.uri],
        });
    }

    /**
     * check if file or directory exists
     * @param getUriOptions options for
     * @returns boolean true if file exists
     */
    async checkFileExists(getUriOptions: GetUriOptions): Promise<boolean> {
        try {
            await Filesystem.stat(getUriOptions);
            return true;
        } catch (checkDirException) {
            return false;
        }
    }

    public table(table: any[]): void {
        this.buffer.push(this.logToString("INFO", [JSON.stringify(table, null, " ")]));
    }

    private async writeLogs(events: string[]): Promise<void> {
        // Don't do anything if there are no new events
        if (!events || events.length <= 0) {
            return;
        }

        // Combine the buffered events to a string payload
        // (need to tack on a newline at the end since join doesn't do that)
        const outputText = `${ events.join("\n") }\n`;

        // Write the encoded content to the RotatingFileStream instance.
        // NOTE: the stream will handle file swapping in the background, so we don't have to handle that part directly.
        await Filesystem.appendFile({ path: this.path, directory: this.directory, data: outputText, encoding: Encoding.UTF8 });
    }

    private logToString(level: string, args: string[]) {
        let message = "[" + level + "] " + args.join(" ");
        return message;
    }

    private async createFileIfNeeded() {
        let exists = await this.checkFileExists({ path: this.path, directory: this.directory });
        if (!exists) {
            await Filesystem.writeFile({ path: this.path, directory: this.directory, data: "", encoding: Encoding.UTF8, recursive: true });
        }
    }
}
