import { Injectable } from "@angular/core";
import { App } from "@capacitor/app";
import { VersionDto } from "../dtos/version.dto";
import { DeviceHelper } from "../helpers/device.helper";

@Injectable({
    providedIn: "root",
})
export class AppVersionService {
    constructor(public deviceHelper: DeviceHelper) {
    }

    public getAppVersion(): Promise<VersionDto> {
        return new Promise<VersionDto>(async resolve => {
            if (this.deviceHelper.isRunningOnDevice()) {
                resolve(new VersionDto(0, (await App.getInfo()).version));
            } else {
                resolve(new VersionDto(0, "99")); // For browser
            }
        });
    }
}
