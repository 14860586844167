import { LogLevel } from "../gyzmo-commons/interfaces/logLevel";
import { EnvironmentSpec } from "./environment.model.specif";

export const environment: EnvironmentSpec = {
    production: false,
    customer: "Gyzmo",
    defaultLogin: "VEGA",
    defaultPassword: "VEGAGCSI",
    mockCamera: false,
    mocked: true,
    servers: [
        {
            name: "Hexanet Prod",
            url: "https://api.gyzmo-technology.net/gyzmo",
            apiKey: "22754e75-062b-40e2-8591-8fe57bdb2492",
            defaultToken: "KY_MOBILITE",
            token: "KY_MOBILITE",
            context: "",
        },
    ],
    logging: {
        level: LogLevel.DEBUG,
        consoleLogger: { active: true },
        fileLogger: { active: false },
    },
};
