import { Injectable } from "@angular/core";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/NotImplementedError";
import { EquipmentDto } from "../../dto/equipment.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";
import { ModelWsDao } from "./model.ws.dao";

@Injectable({
    providedIn: "root",
})
export class EquipmentWsDao extends WsDao<EquipmentDto> {
    static WS = "equipments";
    static HISTORICAL_MILEAGES_WS = "historical-mileages";

    constructor(private modelWsDao: ModelWsDao) {
        super();
    }

    public getById(serverConnection: ServerConnection, id: string): Promise<EquipmentDto> {
        return new Promise<EquipmentDto>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.get(this.constructor.name, EquipmentWsDao.WS + "/" + id, tokens)
                .then(async response => {
                    if (response.status == 204) {
                        resolve(new EquipmentDto());
                    } else {
                        let equipment = EquipmentDto.fromBody(response.body);
                        let promises = [];

                        if (equipment.model.id) {
                            promises.push(this.modelWsDao.getById(serverConnection, equipment.model.id)
                                .then(value => {
                                    equipment.model = value;
                                }));
                        }

                        Promise.all(promises)
                            .then(ignored => {
                                resolve(equipment);
                            });
                    }
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getList(serverConnection: ServerConnection, search?: string): Promise<EquipmentDto[]> {
        return new Promise<EquipmentDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            let url = EquipmentWsDao.WS + "/";
            if (search) {
                url += "?_limit=150&_order=F090LIB&search=" + search;
            }

            serverConnection.get(this.constructor.name, url, tokens)
                .then(response => {
                    if (response.status == 204) {
                        resolve([]);
                    } else {
                        let result = [];
                        (response.body as Array<any>).forEach(value => {
                            let constructionSite = EquipmentDto.fromBody(value);
                            result.push(constructionSite);
                        });
                        resolve(result);
                    }
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, equipment: EquipmentDto): Promise<EquipmentDto> {
        throw new NotImplementedError();
    }

    public getUpdatedMileage(serverConnection: ServerConnection, id: string): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.get(this.constructor.name, EquipmentWsDao.WS + "/" + id + "/" + EquipmentWsDao.HISTORICAL_MILEAGES_WS, tokens)
                .then(response => {
                    if (response.status == 204) {
                        resolve(0);
                    } else {
                        if (response.body.length > 0) {
                            resolve(Math.floor(Number(response.body[0].mileage)));
                        } else {
                            resolve(0);
                        }
                    }
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getListByModel(serverConnection: ServerConnection, modelId: string) {
        return new Promise<EquipmentDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            let url = EquipmentWsDao.WS;
            url += "?_limit=NOLIMIT&_order=F090LIB&model=" + modelId;

            serverConnection.get(this.constructor.name, url, tokens)
                .then(response => {
                    if (response.status == 204) {
                        resolve([]);
                    } else {
                        let result = [];
                        (response.body as Array<any>).forEach(value => {
                            let constructionSite = EquipmentDto.fromBody(value);
                            result.push(constructionSite);
                        });
                        resolve(result);
                    }
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
